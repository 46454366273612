import {
  faList,
  faMapPin,
  faTableColumns,
} from "@fortawesome/free-solid-svg-icons";
import { IMenuItem } from "../../../utils/MainMenu";
import { IntlShape } from "react-intl";

interface MainMenuProps {
  activePage: string;
}

export const ProfilesInnerMenu = (
  intl: IntlShape,
  activePage: string,
): IMenuItem[] => {
  const menuItems = [
    {
      id: "PROFILES.INNERMENU.OVERVIEW",
      label: intl.formatMessage({
        id: "PROFILES.INNERMENU.OVERVIEW",
        defaultMessage: "Overview",
      }),
      path: "/farmers/dashboard",
      isActive:
        activePage.toLowerCase() === "/farmers" ||
        activePage.toLowerCase().split("/").includes("dashboard"),
      icon: faTableColumns,
    },
    {
      id: "PROFILES.INNERMENU.LISTVIEW",
      label: "List View",
      path: "/farmers/profiles",
      isActive: activePage.toLowerCase() === "/farmers/profiles",
      icon: faList,
    },
    {
      id: "PROFILES.INNERMENU.MAPVIEW",
      label: "Map View",
      path: "/farmers/map",
      isActive: activePage.toLowerCase() === "/farmers/map",
      icon: faMapPin,
    },
  ];
  return menuItems;
};
