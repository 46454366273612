import TableWrapper from "../../components/shared/TableWrapper";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import { useEffect, useState } from "react";
import { ITableColumn, XTable } from "../../components/shared/XTable";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../auth";
import { ICollectionCentre } from "../settings/interfaces/IHub";
import {
  IApiResponse,
  IPaginationMetadata,
} from "../../interfaces/IApiResponse";
import { SETTINGS_CONSTANTS } from "../../../data/redux/settings/settingsReducer";
import { IState } from "../../../data/types";
import { remoteRoutes } from "../../../data/constants";
import { buildUrl } from "../../../utils/queryBuilder";
import { get } from "../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../data/toastDefaults";
import XPagination from "../../components/shared/XPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  INVENTORY_SALES_CONSTANTS,
} from "../../../data/redux/inventory/batchSalesReducer";
import { FormattedMessage, useIntl } from "react-intl";
import { IBatchSale } from "./interfaces/IBatchSale";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { usePageData } from "../../../_theme/layout/core";

const columns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.DATEOFSALE",
    label: "Date of Sale",
    id: "date",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.BATCHNUMBER",
    label: "Batch Number",
    id: "batchNumber",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.BUYER",
    label: "Buyer",
    id: "buyerName",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.PRODUCTS",
    label: "Product",
    id: "product",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.VARIETY",
    label: "Variety",
    id: "variety",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.QUANTITY",
    label: "Quantity",
    id: "quantity",
    isNumberFormat: false,
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.UNITPRICE",
    label: "Unit Price",
    isNumberFormat: true,
    id: "unitPrice",
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.SELLINGPRICE",
    label: "Selling Price",
    isNumberFormat: true,
    id: "sellingPrice",
    textAlign: "text-end",
  },
];

const BatchSales = () => {
  document.title = "Inventory > Sales";

  const intl = useIntl();

  const dispatch = useDispatch();

  const { currentUser } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [filteredSales, setFilteredSales] = useState<IBatchSale[]>([]);

  const [pagination, setPagination] = useState<IPaginationMetadata>({
    pageSize: 25,
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPage: false,
    totalItems: 0,
    totalPages: 0,
  });

  const { data, loading }: any = useSelector(
    (state: IState) => state.batchSales,
  );

  const [selectedSales, setSelectedSale] = useState<IBatchSale>();

  const { setPageTitle } = usePageData()

  useEffect(() => {
    setPageTitle("Inventory / Sales")
  }, [])

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: SETTINGS_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  const filterBatchSales = () => {
    setFilteredSales(data);
  };

  useEffect(() => {
    filterBatchSales();
  }, [searchTerm, data]);

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize);
  }, [page]);

  const handleFetchData = (page: number, pageSize: number) => {
    if (remoteRoutes.inventoryService) {
      const sellerId = currentUser?.hubId;
      let params: any = { sellerId, page, pageSize };

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(
        remoteRoutes.inventoryService,
        "/trade/history",
        params,
      );
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;
          setPagination(paginationMetadata);

          dispatch({
            type: INVENTORY_SALES_CONSTANTS.FETCH_DATA,
            payload: data,
          });

          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: ICollectionCentre) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        // Remove the id if it's already in the array
        newSelected.splice(index, 1);
      } else {
        // Add the id if it's not in the array
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: IBatchSale) => {
    setDrawerVisible(!drawerVisible);
    setSelectedSale(item);
  };

  const formattedDate = Array.isArray(data)
    ? data.map((row: { date: string, quantity: string, unit: string }) => ({
      ...row,
      date: printNaturalDateShort(new Date(row.date)),
      quantity: `${Number(row.quantity).toLocaleString()} ${row.unit}`
    }))
    : [];

  return (
    <>
      <div className={"py-3"}>
        <InnerMenu module={Modules.Inventory} />
      </div>

      <TableWrapper>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="input-group w-auto">
            <input
              type="text"
              onKeyUp={(event) => handleSearch(event)}
              className="form-control"
              placeholder={intl.formatMessage({
                id: "TABLE.SEARCH",
                defaultMessage: "Type to search...",
              })}
            />
            <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>

          <div className="action-buttons">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary ms-2"
            >
              <FontAwesomeIcon icon={faFilter} className="text-orange" />
              <span className="ms-2">
                {" "}
                <FormattedMessage
                  id="BUTTONS.FILTERS"
                  defaultMessage="Filters"
                />
              </span>
            </button>
          </div>
        </div>

        <XTable
          checked={false}
          selected={selected}
          onSelectAll={(rows) => handleSelectAll(rows)}
          onRowClick={(row) => handleClickRow(row)}
          onCheckRow={(row) => handleCheckRow(row)}
          data={formattedDate}
          columns={columns}
          loading={loading}
        />

        <XPagination
          dataLength={data.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>
    </>
  );
};

export default BatchSales;
