import { TextField, InputProps } from "@mui/material";
import { useField } from "formik";
import { useEffect } from "react";

type NumberFormatterProps = {
  placeholder?: string;
  className: string;
  name: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  initialValue: number | string | undefined;
  allowDecimal?: boolean;
  label: string;
  helperText?: string;
  inputProps?: InputProps;
  disabled?: boolean;
};

const NumberFormatter = ({
  label,
  helperText,
  placeholder = '',
  className = '',
  name,
  setFieldValue,
  initialValue,
  allowDecimal = true,
  inputProps,
  disabled = false,
}: NumberFormatterProps) => {
  const formatValue = (value: string): string => {
    if (!value) return '';
    const cleanValue = value.replace(allowDecimal ? /[^0-9.]/g : /[^0-9]/g, '');
    const parts = cleanValue.split('.');
    let whole = parts[0];
    const decimal = parts.length > 1 ? parts[1].replace(/[^0-9]/g, '') : undefined;
    if (!isNaN(parseFloat(whole))) {
      whole = parseInt(whole, 10).toLocaleString();
    }
    return decimal !== undefined ? `${whole}.${decimal}` : whole;
  };

  const [field, meta] = useField({ name });

  useEffect(() => {
    if (initialValue !== undefined) {
      const formattedInitialValue = formatValue(initialValue.toString());
      setFieldValue(name, formattedInitialValue);
    }
  }, [name, initialValue, setFieldValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const formattedValue = formatValue(value);
    setFieldValue(name, formattedValue || '');
  };

  return (
    <TextField
      {...field}
      sx={{
        '& label.Mui-focused': {
          color: '#1C5838',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: '#1C5838',
          },
        },
      }}
      label={label}
      type="text"
      size="medium"
      disabled={disabled}
      className={`form-control mb-4 ${className}`}
      variant="standard"
      InputProps={{ ...inputProps, onChange: handleChange }}
      placeholder={placeholder}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error ? meta.error : helperText}
    />
  );
};

export default NumberFormatter;