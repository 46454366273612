import { useDispatch } from "react-redux";
import { useAuth } from "../../../auth";
import { useEffect, useState } from "react";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { IContactPerson } from "../../interfaces/IHub";
import { put } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { Field, Formik } from "formik";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { XAutoComplete } from "../../../../components/shared/forms/XAutoComplete";
import { useIntl } from "react-intl";
import { ContactPersonSchema } from "../interfaces/ContactPersonSchema";
import { useGeographicData } from "../../../../hooks/useGeographicData";

interface Props {
    initialValues: IContactPerson
}

const EditContactPersonForm = ({ initialValues }: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { currentUser } = useAuth();

    const {
        districts,
        counties,
        subCounties,
        parishes,
        villages,
        setSelectedDistrictId,
        setSelectedCountyId,
        setSelectedSubCountyId,
        setSelectedParishId
    } = useGeographicData();

    const [modalKey, setModalKey] = useState(Date.now());

    useEffect(() => {
        if (initialValues.districtId) {
            setSelectedDistrictId(initialValues.districtId);
        }
    }, [initialValues.districtId, setSelectedDistrictId]);

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const url = buildUrl(remoteRoutes.onboardingService, `/contact/persons/${values.id}`);
        const data: IContactPerson = {
            ...values,
            district: values.district.label,
            districtId: values.district.id,
            subCounty: values.subCounty.label,
            county: values.county.label,
            parish: values.parish.label,
            village: values.village.label,
        };

        put(url, data, (response) => {

            CloseModal("new-contact-person", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.UPDATE_CONTACT_PERSON,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        }, () => {

        })
    };

    const validationSchema = ContactPersonSchema(intl)
    return (
        <Formik
            initialValues={{
                ...initialValues,
                district: initialValues.district ? {
                    label: initialValues.district,
                    value: initialValues.district,
                    id: initialValues.districtId,
                } : null,
                county: initialValues.county ? {
                    label: initialValues.county,
                    value: initialValues.county,
                    id: null
                } : null,
                subCounty: initialValues.subCounty ? {
                    label: initialValues.subCounty,
                    value: initialValues.subCounty,
                    id: null
                } : null,
                parish: initialValues.parish ? {
                    label: initialValues.parish,
                    value: initialValues.parish,
                    id: null
                } : null,
                village: initialValues.village ? {
                    label: initialValues.village,
                    value: initialValues.village,
                    id: null
                } : null,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
                const districtID = values.district?.id ?? null;
                setSelectedDistrictId(districtID)
                const countyID = values.county?.id ?? null;
                setSelectedCountyId(countyID)
                const subCountyID = values.subCounty?.id ?? null;
                setSelectedSubCountyId(subCountyID)
                const parishID = values.parish?.id ?? null;
                setSelectedParishId(parishID)
                return <ModalWrapper
                    id={"edit-contact-person"}
                    title={intl.formatMessage({
                        id: "FORM.TITLE.EDITCONTACTPERSON",
                        defaultMessage: "Edit Contact Person",
                    })}
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label={intl.formatMessage({ id: "FORM.NAME", defaultMessage: "Name" })}
                                name="name"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label={intl.formatMessage({ id: "FORM.PHONENUMBER", defaultMessage: "Phone Number" })}
                                name="phoneNumber"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label={intl.formatMessage({ id: "FORM.EMAIL", defaultMessage: "Email" })}
                                name="email"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                label={intl.formatMessage({ id: "FORM.DISTRICT", defaultMessage: "District" })}
                                options={districts}
                                name="district"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={counties}
                                label={`${intl.formatMessage({ id: "FORM.COUNTY", defaultMessage: "County" })}`}
                                name="county"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={subCounties}
                                label={`${intl.formatMessage({ id: "FORM.SUBCOUNTY", defaultMessage: "Sub County" })}`}
                                name="subCounty"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={parishes}
                                label={`${intl.formatMessage({ id: "FORM.PARISH", defaultMessage: "Parish" })}`}
                                name="parish"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={villages}
                                label={intl.formatMessage({ id: "FORM.VILLAGE", defaultMessage: "Village" })}
                                name="village"
                            />
                        </div>
                    </div>

                </ModalWrapper>
            }}
        </Formik>
    );
}

export default EditContactPersonForm;