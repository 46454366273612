import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  faEllipsisH,
  faPeopleGroup,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HubDetails from "./partials/_details";
import HubCollectionCentres from "./partials/_collectionCentres";
import HubCostCentres from "./partials/_costCentres";
import HubProcesses from "./partials/_processes";
import { HUBS_CONSTANTS } from "../../../../data/redux/settings/hubsReducer";
import { IHub, IProduct } from "../../settings/interfaces/IHub";
import HubDetailsLoader from "../../../components/shared/loaders/HubDetailsLoader";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { PageHeader } from "../../../components/shared/PageHeader";
import { IHeaderButtonProps } from "../../../components/shared/SettingsPageHeader";
import DeleteHubForm from "./modals/DeleteHubForm";
import EditHubForm from "./modals/EditHubForm";
import { getRandomColor } from "../../../hooks/colorRandomizer";
import { useAuth } from "../../auth";
import AddProductForm from "../../settings/products/modals/AddProductForm";
import { IState } from "../../../../data/types";
import { FormattedMessage, useIntl } from "react-intl";
import { FarmerProfiles } from "../../profiling/FarmerProfiles";
import Hubs from "./Hubs";
import { IBreadcrumb } from "../../../components/shared/Breadcrumbs";
import { Users } from "../userManagement/Users";
import { IPaginationMetadata } from "../../../interfaces/IApiResponse";
import { authService } from "../../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../../hooks/roleChecker";
import { usePageData } from "../../../../_theme/layout/core";

type IProps = {
  mau: string | undefined;
};

const HubDetailsPage = (props: IProps) => {
  const intl = useIntl();
  const tabs = [
    { name: "collection-centres", label: "Collection Centers" },
    { name: "details", label: "Details" },
    { name: "users", label: "Users" },
  ];

  const urlParams = useParams();
  const { currentUser } = useAuth();
  const hubId = urlParams.hubId ?? currentUser?.hubId;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const currentTab = searchParams.get("tab") || "details";

  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);

  const dispatch = useDispatch();
  const hub = useSelector((state: IState) =>
    state.hubs?.data?.find((item) => item?.id === hubId),
  );
  const [hubDetails, setHubDetails] = useState(hub);

  const products: IProduct[] = hubDetails?.products || [];

  const [pagination, setPagination] = useState<IPaginationMetadata>({
    pageSize: 25,
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPage: false,
    totalItems: products.length,
    totalPages: 0,
  });

  const [importing, setImporting] = useState<boolean>(false);

  const mau: string = hubDetails?.minimumAggregationUnit ?? "";

  const setLoading = (status: boolean) => {
    dispatch({
      type: HUBS_CONSTANTS.LOADING_DATA,
      payload: status,
    });
  };

  const getTab = (name: string) => {
    return tabs.find((q) => q.name === name);
  };

  document.title = `${hubDetails?.name} > ${currentTab}`;
  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const { setPageTitle } = usePageData()

  useEffect(() => {
    setPageTitle(`${capitalize(currentTab)}`)
  }, [])

  useEffect(() => {
    handleFetchData();
  }, [hubId]);

  useEffect(() => {
    if (hub) {
      setHubDetails(hub);
    }
  }, [hub]);

  const handleFetchData = () => {
    setLoading(true);

    if (remoteRoutes.onboardingService) {
      const url = buildUrl(remoteRoutes.onboardingService, `/hubs/${hubId}`);

      get(
        url,
        (response: IHub) => {
          response.products?.map((product) => ({
            ...product,
            unitString: product.units?.map((u) => u.name).join(","),
          }));

          setHubDetails(response);

          dispatch({
            type: HUBS_CONSTANTS.GET_DETAILS,
            payload: response,
          });

          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        () => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
          setLoading(false);
        },
        () => dispatch({ type: HUBS_CONSTANTS.STOP_FETCH }),
      );
    }
  };

  const actionButtons: IHeaderButtonProps[] = [];

  const roles = authService.getRoles();
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);
  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isMerchant = IsMerchant(roles);

  if (isHubManager || isHubAdmin || isGlobalAdmin || isMerchant) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
      cssClass: "btn btn-primary btn-sm me-2",
      dataTarget: "#edit-hub",
      dataToggle: "modal",
    });
  }

  if (isHubAdmin || isGlobalAdmin || isMerchant) {
    actionButtons.push({
      label: `${intl.formatMessage({ id: "BUTTONS.DELETE", defaultMessage: "Delete" })}`,
      cssClass: "btn btn-danger btn-sm",
      dataTarget: "#delete-hub",
      dataToggle: "modal",
    });
  }

  const addProductButton: IHeaderButtonProps[] = [
    {
      label: `${intl.formatMessage({ id: "BUTTONS.ADDPRODUCT", defaultMessage: "Add a product" })}`,
      cssClass: "btn btn-outline-primary btn-sm",
      dataTarget: "#add-product",
      dataToggle: "modal",
      onSubmit: (values: any) => { },
      processing: importing,
    },
  ];

  const handleTabClick = (tab: string) => {
    navigate(`${location.pathname}?tab=${tab}`);
  };

  useEffect(() => {
    setBreadcrumbs([
      { label: props.mau ?? "Hubs", url: "/hubs" },
      { label: hubDetails?.name ?? "", url: undefined },
      { label: getTab(currentTab)?.label ?? "", url: undefined },
    ]);
  }, [dispatch, currentTab]);

  return (
    <>
      {!hubDetails && <HubDetailsLoader />}
      {hubDetails && (
        <>
          <PageHeader breadcrumbs={breadcrumbs} />
          <div className="card shadow-sm border-0 mb-3">
            <div className="pt-3 px-3">
              <div className="d-flex justify-content-between w-100 mb-3">
                <div className="d-flex justify-content-start w-50 align-items-center">
                  <div className="py-3 px-3 rounded-1 bg-primary">
                    {/*<h2>{hubDetails.name?.split(' ').map(name => name[0]).join('')}</h2>*/}
                    <FontAwesomeIcon icon={faPeopleGroup} size={"2x"} />
                  </div>
                  <div className="px-3">
                    <h5>{hubDetails?.name}</h5>
                    <div className="d-flex gap-1 badge-container">
                      {hubDetails?.products?.map((product, index) => {
                        return (
                          <span className={`badge ${getRandomColor()}`}>
                            {product.name}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <div className="action-buttons">
                    {addProductButton.map((button, index) => {
                      return (
                        <button
                          data-bs-toggle={button.dataToggle}
                          data-bs-target={button.dataTarget}
                          className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                          key={index}
                          onClick={button.onClick}
                        >
                          {button.deleteIcon && (
                            <FontAwesomeIcon icon={faTrash} />
                          )}
                          {button.processing
                            ? `${intl.formatMessage({
                              id: "LOADERS.PLEASEWAIT",
                              defaultMessage: "Please wait...",
                            })}`
                            : button.label}
                        </button>
                      );
                    })}
                  </div>

                  <div>
                    <button
                      className={`btn btn-secondary btn-sm `}
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {actionButtons.map((button, index) => {
                        return (
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              key={index}
                              data-bs-toggle={button.dataToggle}
                              data-bs-target={button.dataTarget}
                              onClick={() => button.onClick}
                            >
                              {button.processing
                                ? `${intl.formatMessage({
                                  id: "LOADERS.PLEASEWAIT",
                                  defaultMessage: "Please wait...",
                                })}`
                                : button.label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <ul className="nav nav-underline">
                <li
                  onClick={() => handleTabClick("details")}
                  className="nav-item"
                >
                  <button
                    className={`nav-link ${currentTab === "details" ? "active" : ""}`}
                  >
                    <FormattedMessage
                      id="TABS.DETAILS"
                      defaultMessage="Details"
                    />
                  </button>
                </li>

                {mau && (
                  <li
                    onClick={() => handleTabClick("hubs")}
                    className="nav-item"
                  >
                    <button
                      className={`nav-link ${currentTab === "hubs" ? "active" : ""}`}
                    >
                      {mau}
                    </button>
                  </li>
                )}
                {!mau && (
                  <li
                    onClick={() => handleTabClick("farmers")}
                    className="nav-item"
                  >
                    <button
                      className={`nav-link ${currentTab === "farmers" ? "active" : ""}`}
                    >
                      <FormattedMessage
                        id="TABS.FARMERS"
                        defaultMessage="Farmers"
                      />
                    </button>
                  </li>
                )}

                {/*<li onClick={() => setCurrentTab('inputDistribution')} className="nav-item">*/}
                {/*    <a className={`nav-link ${active === 'inputDistribution' ? 'active' : ''}`}*/}
                {/*       href="#">
                                        <FormattedMessage
                                          id="TABS.INPUTS"
                                          defaultMessage="Inputs"
                                        />
                                </a>*/}
                {/*</li>*/}
                <li
                  onClick={() => handleTabClick("collection-centres")}
                  className="nav-item"
                >
                  <button
                    className={`nav-link ${currentTab === "collection-centres" ? "active" : ""}`}
                  >
                    <FormattedMessage
                      id="TABS.COLLECTIONCENTERS"
                      defaultMessage="Collection Centres"
                    />
                  </button>
                </li>
                {/*<li onClick={() => setCurrentTab('costCentres')} className="nav-item">*/}
                {/*    <a className={`nav-link ${active === 'costCentres' ? 'active' : ''}`}*/}
                {/*       href="#">
                                        <FormattedMessage
                                          id="TABS.COSTCENTERS"
                                          defaultMessage="Cost Centres"
                                        />
                                </a>*/}
                {/*</li>*/}
                {/*<li onClick={() => setCurrentTab('processes')} className="nav-item">*/}
                {/*    <a className={`nav-link ${active === 'processes' ? 'active' : ''}`}*/}
                {/*       href="#">
                                <FormattedMessage
                      id="TABS.PROCESSES"
                      defaultMessage="Processes"
                    /></a>*/}
                {/*</li>*/}

                <li
                  onClick={() => handleTabClick("users")}
                  className="nav-item"
                >
                  <button
                    className={`nav-link ${currentTab === "users" ? "active" : ""}`}
                  >
                    <FormattedMessage id="TABS.USERS" defaultMessage="Users" />
                  </button>
                </li>
              </ul>

              <div></div>
            </div>
          </div>

          <div className="">
            {currentTab === "details" && <HubDetails hub={hubDetails} />}
            {currentTab === "hubs" && <Hubs hubId={hubId} />}
            {currentTab === "farmers" && (
              <FarmerProfiles showHeader={false} hubId={hubId} />
            )}
            {currentTab === "users" && (
              <Users includeMyHubUsers={false} hubId={hubId} />
            )}
            {/*{active === 'inputDistribution' && <HubInputs hubId={hubId}/>}*/}
            {currentTab === "collection-centres" && (
              <HubCollectionCentres hubId={hubId} />
            )}
            {currentTab === "costCentres" && <HubCostCentres hubId={hubId} />}
            {currentTab === "processes" && <HubProcesses hubId={hubId} />}
          </div>
        </>
      )}

      {hubDetails && (
        <AddProductForm
          hubId={hubId}
          items={hubDetails.products ?? []}
          pagination={pagination}
        />
      )}
      {hubDetails && <EditHubForm initialValues={hubDetails} mau={mau} />}
      {hubDetails && <DeleteHubForm item={hubDetails} mau={mau} />}
    </>
  );
};

export default HubDetailsPage;
