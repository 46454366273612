import React, { useEffect, useState } from "react";
import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader";
import { FormattedMessage, useIntl } from "react-intl";
import { printProcessingStatus } from "../../../utils/enumHelpers";
import EditProcessRunForm from "./modals/EditProcessRunForm";
import DeleteProcessRun from "./modals/DeleteProcessRun";
import EndProcessForm from "./modals/EndProcessForm";
import { useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { printNaturalDateTimeShort } from "../../../utils/dateHelpers";

type Props = {
    close: () => void;
    show: boolean;
    processRunId: any;
};

const ProcessRunDetails = ({ close, show, processRunId }: Props) => {

    const intl = useIntl();

    const processRun = useSelector((state: IState) =>
        Array.isArray(state.processRuns.data)
            ? state.processRuns.data.find((pr) => pr.id === processRunId)
            : undefined,
    );

    const [localProcessRun, setLocalProcessRun] = useState(processRun)

    useEffect(() => {
        setLocalProcessRun(processRun)
    }, [processRun])

    const actionButtons: IHeaderButtonProps[] = [
        {
            cssClass: "btn btn-danger btn-sm",
            dataTarget: "#delete-process-run",
            dataToggle: "modal",
            deleteIcon: true,
        }
    ];

    if (localProcessRun?.status === 2) {
        const editButton = {
            label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
            cssClass: "btn btn-primary btn-sm me-1",
            dataTarget: "#edit-process-run",
            dataToggle: "modal",
        };

        actionButtons.splice(0, 0, editButton);
    }

    return (
        <>
            <XSidebarWrapper
                title={"Process Run Details"}
                width={50}
                close={close}
                show={show}
            >
                <div className="d-flex justify-content-between w-100 align-items-baseline border-bottom mb-2">
                    <div className="d-flex w-100 pb-2">
                        <div className="col-9">
                            <h5>{localProcessRun?.processName}</h5>
                            <div className="mb-1">
                                {localProcessRun?.productName} - {localProcessRun?.inputVarietyName} - {localProcessRun?.batchNumber}
                            </div>
                            <div className="mb-1">
                                {printProcessingStatus(localProcessRun?.status)}
                            </div>
                            <div className="d-flex row">
                                <div className="col">
                                    <strong className="small fw-semibold">
                                        <FormattedMessage
                                            id="DETAILS.CONVERSIONRATE"
                                            defaultMessage="Conversion rate"
                                        />
                                    </strong>
                                    <br />
                                    <div className="badge bg-success span">
                                        {localProcessRun?.conversionRate !== null ? `${(localProcessRun?.conversionRate)?.toFixed(2)} %` : "-"}
                                    </div>
                                </div>
                                <div className="col">
                                    <strong className="small fw-semibold">
                                        <FormattedMessage
                                            id="DETAILS.PROCESSINGLOSS"
                                            defaultMessage="Processing loss"
                                        />
                                    </strong>

                                    <br />
                                    <div className="badge bg-danger span">
                                        {localProcessRun?.shrinkage !== null ? `${(localProcessRun?.shrinkage)?.toFixed(2)} %` : "-"}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="action-buttons mt-auto d-flex flex-wrap justify-content-end col">
                            {localProcessRun?.status === 1 ? (
                                <>
                                    <div
                                        className="btn btn-outline-primary btn-sm me-1"
                                        data-bs-toggle={"modal"}
                                        data-bs-target={"#end-process"}
                                    >
                                        <FormattedMessage id="BUTTONS.ENDPROCESS" defaultMessage={"End Process"} />
                                    </div>
                                </>
                            ) : null}
                            {actionButtons.map((button, index) => {
                                return (
                                    <button
                                        data-bs-toggle={button.dataToggle}
                                        data-bs-target={button.dataTarget}
                                        className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                                        key={index}
                                        onClick={button.onClick}
                                    >
                                        {button.deleteIcon && <FontAwesomeIcon icon={faTrash} />}
                                        {button.processing
                                            ? `${intl.formatMessage({
                                                id: "LOADERS.PLEASEWAIT",
                                                defaultMessage: "Please wait...",
                                            })}`
                                            : button.label}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className="p-3 bg-gray rounded-3 mb-2">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-2">
                            <strong className="small fw-semibold">
                                <FormattedMessage
                                    id="DETAILS.STARTEDON"
                                    defaultMessage="Started on"
                                />
                            </strong>
                            <br />
                            <span>{printNaturalDateTimeShort(localProcessRun?.startTime) || "-"}</span>
                        </div>
                        <div className="col-lg-6 col-12 mb-2">
                            <strong className="small fw-semibold">
                                <FormattedMessage id="DETAILS.INITIALVARIETY" defaultMessage="Initial Variety" />
                            </strong>
                            <br />
                            <span>{localProcessRun?.inputVarietyName || "-"} - {Number(localProcessRun?.inputQuantity).toLocaleString()} {localProcessRun?.inputUnit}</span>
                        </div>
                        <div className="col-lg-6 col-12 mb-2">
                            <strong className="small fw-semibold">
                                <FormattedMessage
                                    id="DETAILS.ENDEDON"
                                    defaultMessage="Ended on"
                                />
                            </strong>
                            <br />
                            <span>{printNaturalDateTimeShort(localProcessRun?.endTime) || "-"}</span>
                        </div>
                        <div className="col-lg-6 col-12">
                            <strong className="small fw-semibold">
                                <FormattedMessage
                                    id="DETAILS.DURATION"
                                    defaultMessage="Duration"
                                />
                            </strong>
                            <br />
                            <span>
                                {localProcessRun?.duration
                                    ? (() => {
                                        const duration = localProcessRun?.duration;
                                        if (isNaN(duration)) return "-";

                                        if (duration < 1) {
                                            const minutes = Math.round(duration * 60);
                                            return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
                                        } else if (duration >= 1) {
                                            return `${duration.toFixed(2)} ${duration === 1 ? "hour" : "hours"}`;
                                        } else {
                                            return "-";
                                        }
                                    })()
                                    : "-"}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="p-3 bg-gray rounded-3 mb-2">
                    <div className="row">
                        {localProcessRun?.outputs && localProcessRun.outputs.length > 0 ? (
                            localProcessRun?.outputs.map((output: { varietyId: string | null, variety: string | null, quantity: number | null, unit: string | null }, index: number) => (
                                <div key={index} className="col-lg-6 col-12 mb-2">
                                    <strong className="fw-semibold">
                                        {output.variety ?? 'N/A'}
                                    </strong>
                                    <br />
                                    <span>{Number(output.quantity).toLocaleString() ?? 0} {output.unit ?? 'N/A'}</span>
                                </div>
                            ))
                        ) : (
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">
                                    <FormattedMessage
                                        id="DETAILS.OUTPUTS"
                                        defaultMessage="Outputs"
                                    />
                                </strong>
                                <br />
                                <span>-</span>
                            </div>
                        )}
                        <div className="col-lg-12 col-12 mb-4">
                            <strong className="small fw-semibold">
                                <FormattedMessage
                                    id="DETAILS.COMMENTS"
                                    defaultMessage="Comments"
                                />
                            </strong>
                            <br />
                            <span>{localProcessRun?.comment || "-"}</span>
                        </div>
                    </div>
                </div>
            </XSidebarWrapper>

            {localProcessRun && (
                <>
                    <EditProcessRunForm initialValues={localProcessRun} />
                    <DeleteProcessRun
                        item={localProcessRun}
                        close={close}
                    />
                    <EndProcessForm processRun={localProcessRun} />
                </>
            )}

        </>
    )
}

export default ProcessRunDetails