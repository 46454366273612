import React, { useCallback, useEffect, useRef, useState } from "react";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import TableWrapper from "../../components/shared/TableWrapper";
import { faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import { useIntl } from "react-intl";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import XPagination from "../../components/shared/XPagination";
import { IApiResponse, IPaginationMetadata } from "../../interfaces/IApiResponse";
import ProcessRunDetails from "./ProcessRunDetails";
import { PROCESS_RUN_CONSTANTS } from "../../../data/redux/processing/processRunsReducer";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { remoteRoutes } from "../../../data/constants";
import { buildUrl } from "../../../utils/queryBuilder";
import { get } from "../../../utils/ajax";
import { overrideToastDefaults, toastMessages } from "../../../data/toastDefaults";
import toast from "react-hot-toast";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { useAuth } from "../auth";
import { usePageData } from "../../../_theme/layout/core";

const columns: ITableColumn[] = [
    {
        localeId: "TABLE.COLUMN.DATEOFPROCESSING",
        label: "Date of Processing",
        id: "startTime",
        textAlign: "text-start",
    },
    {
        localeId: "TABLE.COLUMN.PROCESS",
        label: "Process",
        id: "processName",
        textAlign: "text-start",
    },
    {
        localeId: "TABLE.COLUMN.BATCHNUMBER",
        label: "Batch Number",
        id: "batchNumber",
        textAlign: "text-start",
    },
    {
        localeId: "TABLE.COLUMN.PRODUCT",
        label: "Product",
        id: "productName",
        textAlign: "text-start",
    },
    {
        localeId: "TABLE.COLUMN.INITIALVARIETY",
        label: "Initial Variety",
        id: "initialVarietyFormatted",
        textAlign: "text-start",
    },
    {
        localeId: "TABLE.COLUMN.STATUS",
        label: "Status",
        id: "status",
        textAlign: "text-start",
    },
];

const ProcessRuns = () => {

    document.title = "Processing > Process Runs";

    const { currentUser } = useAuth();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [selected, setSelected] = useState<any[]>([]);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any | null>(null);

    const { data, loading }: any = useSelector(
        (state: IState) => state.processRuns
    )

    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 25,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0,
    });

    const { setPageTitle } = usePageData()

    useEffect(() => {
        setPageTitle("Processing / Process Runs")
    }, [])

    const setLoading = (state: boolean) => {
        dispatch({
            type: PROCESS_RUN_CONSTANTS.LOADING_PROCESS_RUNS,
            payload: state,
        });
    };

    const debouncedSearch = useCallback(
        debounce((value) => {
            setSearchTerm(value);
            setPage(1);
        }, 800),
        [],
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        debouncedSearch(value);
    };

    const handleClickRow = (item: any) => {
        setDrawerVisible(!drawerVisible);
        setSelectedRow(item);
    };

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination?.pageSize);
    }, [page]);

    const handleFetchData = (page: number, pageSize: number, searchTerm?: string,) => {
        if (remoteRoutes.processingService) {
            const hubId = currentUser?.hubId

            let params: any = { hubId, page, pageSize };

            if (searchTerm) {
                params.searchTerm = searchTerm;
            }

            const url = buildUrl(remoteRoutes.processingService, '/process-runs', params)
            get(url,
                (response: IApiResponse) => {
                    const { data, paginationMetadata } = response;
                    setPagination(paginationMetadata);

                    dispatch({
                        type: PROCESS_RUN_CONSTANTS.FETCH_PROCESS_RUNS,
                        payload: [...data],
                    })

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: PROCESS_RUN_CONSTANTS.STOP_FETCH });
                    setLoading(false);
                })
        }
    }

    const formattedData = Array.isArray(data)
        ? data?.map((row: { inputVarietyName: any; inputQuantity: any; inputUnit: any; outputVarietyName: any; outputQuantity: any; outputUnit: any; startTime: any; }) => ({
            ...row,
            initialVarietyFormatted: row.inputVarietyName && row.inputQuantity && row.inputUnit
                ? `${row.inputVarietyName} (${Number(row.inputQuantity).toLocaleString()} ${row.inputUnit})`
                : row.inputVarietyName || "-",
            startTime: printNaturalDateShort(row.startTime),
        }))
        : [];

    return (
        <>
            <div className="py-3">
                <InnerMenu module={Modules.Processing} />
            </div>

            <TableWrapper>
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-25">
                        <input
                            type="text"
                            ref={searchInputRef}
                            onChange={(event) => handleSearchChange(event)}
                            className="form-control"
                            placeholder={intl.formatMessage({
                                id: "TABLE.SEARCH",
                                defaultMessage: "Type to search...",
                            })}
                        />
                        <span className="input-group-text" id="addon-wrapping">
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                    </div>

                    <div className="action-buttons">
                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                            <FontAwesomeIcon icon={faFilter} className="text-orange" />
                            <span className="ms-2">Filters</span>
                        </button>
                    </div>
                </div>

                <XTable
                    checked={false}
                    selected={selected}
                    onRowClick={(row) => handleClickRow(row)}
                    columns={columns}
                    loading={loading}
                    data={formattedData}
                />

                <XPagination
                    currentPage={page}
                    pagination={pagination}
                    dataLength={data?.length}
                    setPage={(page) => setPage(page)}
                />

            </TableWrapper>

            <ProcessRunDetails
                close={() => setDrawerVisible(false)}
                show={drawerVisible}
                processRunId={selectedRow?.id}
            />
        </>
    )
}

export default ProcessRuns