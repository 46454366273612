import React, { useEffect, useRef, useState } from "react";
import { faFilter, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import TableWrapper from "../../components/shared/TableWrapper";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import XPagination from "../../components/shared/XPagination";
import {
  IApiResponse,
  IPaginationMetadata,
} from "../../interfaces/IApiResponse";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMovementDetails from "./InputMovementDetails";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { useDispatch, useSelector } from "react-redux";
import {
  INPUTS_DISTRIBUTION_CONSTANTS,
} from "../../../data/redux/inputs/inputsReducer";
import { IState } from "../../../data/types";
import { get } from "../../../utils/ajax";
import toast from "react-hot-toast";
import { remoteRoutes } from "../../../data/constants";
import {
  toastMessages,
  overrideToastDefaults,
} from "../../../data/toastDefaults";
import { buildUrl } from "../../../utils/queryBuilder";
import { useAuth } from "../auth";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";
import { authService } from "../../../data/oidc/AuthService";
import { IHub } from "../settings/interfaces/IHub";
import InputMovementsFilterForm from "./modals/InputMovementsFilterForm";
import debounce from "lodash.debounce";
import { FormattedMessage, useIntl } from "react-intl";
import { usePageData } from "../../../_theme/layout/core";

const columns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.MOVINGDATE",
    label: "Moving Date",
    isDate: false,
    id: "formattedDate",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.MOVEMENTNUMBER",
    label: "Movement Number",
    id: "movementNumber",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.INPUTNAME",
    label: "Input Name",
    id: "inputName",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.QUANTITY",
    label: "Quantity",
    isNumberFormat: true,
    id: "quantity",
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.UNIT",
    label: "",
    id: "unitName",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.SOURCE",
    label: "Source",
    id: "sourceCollectionCentreName",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.DESTINATION",
    label: "Destination",
    id: "destinationCollectionCentreName",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.STATUS",
    label: "Status",
    id: "movementStatus",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
];

export const InputMovements = () => {
  const intl = useIntl();
  document.title = "Inputs > Movements";
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [selectedInputRowId, setSelectedInputRowId] = useState<string | null>(
    null,
  );
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState<IPaginationMetadata>({
    pageSize: 15,
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPage: false,
    totalItems: 0,
    totalPages: 0,
  });

  const { setPageTitle } = usePageData()

  useEffect(() => {
    setPageTitle("Inputs / Movements")
  }, [])

  const { data, loading } = useSelector(
    (state: IState) => state.inputMovements,
  );

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
    setPage(1);
  };

  const handleApplyFilters = (FilterValues: any) => {
    setFilters(FilterValues);
    setPage(1);
  };

  const handleFilterClick = () => {
    setFilterVisible(!filterVisible);
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: INPUTS_DISTRIBUTION_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize, searchTerm);
  }, [page, searchTerm, filters]);

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    const hub: IHub | undefined = authService.getHub();

    if (remoteRoutes.profiles) {
      const hubId = currentUser?.hubId;

      const parentHubId = hub?.parentHubId;

      const CollectionCentreIds = hub?.collectionCentres?.map(
        (centre) => centre.id,
      );

      let params: any;

      if (parentHubId === null) {
        params = { hubId, page, pageSize, ...filters };
      } else {
        params = {
          hubId,
          parentHubId,
          CollectionCentreIds,
          page,
          pageSize,
          ...filters,
        };
      }

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(
        remoteRoutes.inputsService,
        "/input/movements",
        params,
      );
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;
          setPagination(paginationMetadata);

          dispatch({
            type: INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA,
            payload: data,
          });

          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: INPUTS_DISTRIBUTION_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchTerm(value);
      setPage(1);
      handleFetchData(page, pagination.pageSize, value);
    }, 800),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleRowClick = (item: any) => {
    setDrawerVisible(true);
    setSelectedRow(item);
    setSelectedInputRowId(item.id);
  };

  const formattedData = data.map((row: any) => ({
    ...row,
    formattedDate: printNaturalDateShort(row.date),
  }));

  return (
    <>
      {loading && <PageHeaderLoader />}
      {!loading && (
        <>
          <div className="py-3">
            <InnerMenu module={Modules.Inputs} />
          </div>
        </>
      )}

      <div className="row">
        <div className={filterVisible ? "col-9" : "col-12"}>
          <TableWrapper>
            <div className="d-flex w-100 align-items-center justify-content-between">
              <div className="input-group w-25">
                <input
                  type="text"
                  // onKeyUp={(event) => handleSearch(event)}
                  ref={searchInputRef}
                  onChange={handleSearchChange}
                  className="form-control"
                  placeholder={`${intl.formatMessage({ id: "TABLE.SEARCH", defaultMessage: "Type to search..." })}`}
                />
                <span className="input-group-text" id="addon-wrapping">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
              <div className={"d-flex"}>
                <div className="action-buttons">
                  <button
                    onClick={handleFilterClick}
                    type="button"
                    className="btn btn-sm btn-outline-secondary ms-2"
                  >
                    <FontAwesomeIcon icon={faFilter} className="text-orange" />
                    <span className={"ms-2"}>
                      <FormattedMessage
                        id={"BUTTONS.FILTERS"}
                        defaultMessage={"Filters"}
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <XTable
              selected={[]}
              checked={false}
              onRowClick={(item) => handleRowClick(item)}
              data={formattedData}
              columns={columns}
              loading={loading}
            />
            <XPagination
              currentPage={page}
              pagination={pagination}
              dataLength={data?.length}
              setPage={setPage}
            />
          </TableWrapper>
        </div>

        {filterVisible && (
          <div className="col-3">
            <TableWrapper>
              <div className="border-bottom mb-2 pt-3 pb-2 d-flex w-100 justify-content-between align-content-center">
                <div style={{ fontSize: 15, fontWeight: 400 }}>Filter</div>
                <div style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={handleFilterClick}
                    size="lg"
                  />
                </div>
              </div>
              <InputMovementsFilterForm
                onApplyFilters={handleApplyFilters}
                setFilters={setFilters}
              />
            </TableWrapper>
          </div>
        )}
      </div>

      <InputMovementDetails
        close={() => setDrawerVisible(false)}
        show={drawerVisible}
        selectedRow={selectedRow}
        selectedInputRowId={selectedInputRowId}
        pagination={pagination}
      />
    </>
  );
};
