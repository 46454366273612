import { useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";
import React, { useEffect, useState } from "react";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { get, put } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { IOption, XAutoComplete } from "../../../../components/shared/forms/XAutoComplete";
import { useAuth } from "../../../auth";
import { HUBS_CONSTANTS } from "../../../../../data/redux/settings/hubsReducer";
import { HubSchema, IHubDto } from "../interfaces/IHubDto";
import { IHub } from "../../../settings/interfaces/IHub";
import { InputAdornment } from "@mui/material";
import { removeTrailingS } from "../../../../hooks/mauNormalizer";
import { IApiResponse } from "../../../../interfaces/IApiResponse";
import { useIntl } from "react-intl";
import { useGeographicData } from "../../../../hooks/useGeographicData";

type Props = {
    initialValues: IHub,
    parent?: IOption,
    mau?: string
}

const EditHubForm = ({ initialValues, parent, mau }: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { currentUser } = useAuth();

    const [hubs, setHubs] = useState<IHub[]>([]);

    const [modalKey, setModalKey] = useState(Date.now());

    const {
        districts,
        counties,
        subCounties,
        parishes,
        villages,
        setSelectedDistrictId,
        setSelectedCountyId,
        setSelectedSubCountyId,
        setSelectedParishId
    } = useGeographicData();

    const hubOptions = hubs.map((hub: IHub) => ({
        label: hub.name,
        id: hub.id
    }));

    const minimalAggregationUnit = removeTrailingS(mau !== "" ? mau : initialValues.minimumAggregationUnit);

    useEffect(() => {
        if (initialValues.districtId) {
            setSelectedDistrictId(initialValues.districtId);
        }
    }, [initialValues.districtId, setSelectedDistrictId]);

    const handleFetchHubs = (page: number, pageSize: number) => {
        if (remoteRoutes.onboardingService) {
            const hubId = currentUser?.hubId;
            const params = { hubId, page, pageSize };
            const url = buildUrl(remoteRoutes.onboardingService, '/hubs', params);
            get(url,
                (response: IApiResponse) => {
                    const { data } = response;
                    setHubs(data);
                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                () => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: HUBS_CONSTANTS.STOP_FETCH });
                });
        }
    };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const url = buildUrl(remoteRoutes.onboardingService, `/hubs/${values.id}`);
        const data: IHubDto = {
            id: values.id,
            name: values.name,
            email: values.email,
            phoneNumber: `+256${values.phoneNumber}`,
            district: values.district.label,
            districtId: values.district.id,
            subCounty: values.subCounty.label,
            county: values.county.label,
            parish: values.parish.label,
            parentHubId: values.parentHubId.id ? values.parentHubId.id : initialValues.parentHubId,
            village: values.village.label,
            postalAddress: values.postalAddress,
            minimumAggregationUnit: values.minimumAggregationUnit,
        }

        put(url, data, () => {

            CloseModal("edit-hub", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: HUBS_CONSTANTS.UPDATE_DATA,
                payload: data
            });

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        const value = event.target.value;
        if (value.length <= 9) {
            setFieldValue("phoneNumber", value);
        }
    };

    useEffect(() => {
        handleFetchHubs(1, 1000);
    }, []);

    const parentHub = hubOptions?.find(h =>
        h.id == initialValues.parentHubId) ?? { id: '', label: '' }

    const validationSchema = HubSchema(intl);

    return (
        <Formik
            initialValues={{
                ...initialValues,
                parentHubId: parentHub,
                district: initialValues.district ? {
                    label: initialValues.district,
                    value: initialValues.district,
                    id: initialValues.districtId
                } : null,
                county: initialValues.county ? {
                    label: initialValues.county,
                    value: initialValues.county,
                    id: null
                } : null,
                subCounty: initialValues.subCounty ? {
                    label: initialValues.subCounty,
                    value: initialValues.subCounty,
                    id: null
                } : null,
                parish: initialValues.parish ? {
                    label: initialValues.parish,
                    value: initialValues.parish,
                    id: null
                } : null,
                village: initialValues.village ? {
                    label: initialValues.village,
                    value: initialValues.village,
                    id: null
                } : null,
                phoneNumber: initialValues.phoneNumber && initialValues.phoneNumber?.length > 9 ? initialValues.phoneNumber?.substring(4) : initialValues.phoneNumber
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, isSubmitting, values, setFieldValue }) => {

                const districtID = values.district?.id ?? null;
                setSelectedDistrictId(districtID)
                const countyID = values.county?.id ?? null;
                setSelectedCountyId(countyID)
                const subCountyID = values.subCounty?.id ?? null;
                setSelectedSubCountyId(subCountyID)
                const parishID = values.parish?.id ?? null;
                setSelectedParishId(parishID)
                
                return <ModalWrapper
                    id="edit-hub"
                    title={`Edit ${minimalAggregationUnit}`}
                    size="lg"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="row">
                        <div className="col-lg-6">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Name"
                                name="name"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Postal Address"
                                name="postalAddress"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Minimum Aggregation Unit"
                                name="minimumAggregationUnit"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={true}
                                component={XAutoComplete}
                                options={hubOptions?.filter(hub => hub.id !== initialValues.id)}
                                label="Parent Hub"
                                name="parentHubId"
                                value={values.parentHubId}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Phone Number"
                                name="phoneNumber"
                                inputProps={{
                                    startAdornment: <InputAdornment position="start">+256</InputAdornment>,
                                    maxLength: 9,
                                    onKeyPress: handleKeyPress,
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, setFieldValue)
                                }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type="email"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Email"
                                name="email"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={districts}
                                label="District"
                                name="district"
                                getOptionLabel={(option: any) => option.label || ""}
                                onChange={(event: any, newValue: any) => {
                                    setFieldValue("district", newValue ? newValue.id : "");
                                }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={counties}
                                label={`${intl.formatMessage({ id: "FORM.COUNTY", defaultMessage: "County" })}`}
                                name="county"
                            />
                        </div>
                        <div className="col-lg-4">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={subCounties}
                                label="Sub-county"
                                name="subCounty"
                            />
                        </div>
                        <div className="col-lg-4">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={parishes}
                                label="Parish"
                                name="parish"
                            />
                        </div>
                        <div className="col-lg-4">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={villages}
                                label="Village"
                                name="village"
                            />
                        </div>
                    </div>
                </ModalWrapper>
            }
            }

        </Formik>
    );
};

export default EditHubForm;
