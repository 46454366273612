import { useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import React, { useState, useCallback } from "react";
import { CollectionCentreSchema } from "../interfaces/CollectionCentreSchema";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { XAutoComplete } from "../../../../components/shared/forms/XAutoComplete";
import { useAuth } from "../../../auth";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import { post } from "../../../../../utils/ajax";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { useParams } from "react-router-dom";
import { IPaginationMetadata } from "../../../../interfaces/IApiResponse";
import { useIntl } from "react-intl";
import { useGeographicData } from "../../../../hooks/useGeographicData";

type Props = {
    hubId?: string
    pagination: IPaginationMetadata
}

interface FormValues {
    name: string;
    district: { id: string; label: string } | null;
    county: { id: string; label: string } | null;
    hubId: string | null;
    subCounty: { id: string; label: string } | null;
    parish: { id: string; label: string } | null;
    village: { id: string; label: string } | null;
    latitude: number | null;
    longitude: number | null;
}

const CreateCollectionCentre: React.FC<Props> = ({ hubId, pagination }) => {
    const intl = useIntl();

    const dispatch = useDispatch();
    const { currentUser } = useAuth();
    const urlParams = useParams()
    hubId = hubId ?? urlParams.hubId;

    const [modalKey, setModalKey] = useState(Date.now());

    const {
        districts,
        counties,
        subCounties,
        parishes,
        villages,
        setSelectedDistrictId,
        setSelectedCountyId,
        setSelectedSubCountyId,
        setSelectedParishId
    } = useGeographicData();

    const initialValues: FormValues = {
        name: '',
        district: null,
        hubId: null,
        county: null,
        subCounty: null,
        parish: null,
        village: null,
        latitude: null,
        longitude: null,
    };

    const handleSubmit = useCallback((values: FormValues, { resetForm, setSubmitting }: any) => {
        const url = buildUrl(remoteRoutes.onboardingService, '/collection/centres')
        const data = {
            ...values,
            district: values.district?.label ?? '',
            districtId: values.district?.id ?? '',
            subCounty: values.subCounty?.label ?? '',
            county: values.county?.label ?? '',
            parish: values.parish?.label ?? '',
            village: values.village?.label ?? '',
            hubId: hubId ?? currentUser?.hubId,
        }

        post(url, data, (response) => {
            CloseModal("new-collection-centre", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_COLLECTION_CENTRES,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => { })
    }, [dispatch, hubId, currentUser, pagination]);

    const validationSchema = CollectionCentreSchema(intl)

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
                const districtID = values.district?.id ?? null;
                setSelectedDistrictId(districtID)
                const countyID = values.county?.id ?? null;
                setSelectedCountyId(countyID)
                const subCountyID = values.subCounty?.id ?? null;
                setSelectedSubCountyId(subCountyID)
                const parishID = values.parish?.id ?? null;
                setSelectedParishId(parishID)
                return (
                    <ModalWrapper
                        id="new-collection-centre"
                        title={`${intl.formatMessage({
                            id: "FORM.TITLE.NEWCOLLECTIONCENTRE",
                            defaultMessage: "New Collection Centre"
                        })}`}
                        size="md"
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({ id: "FORM.NAME", defaultMessage: "Name" })}`}
                                    name="name"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={districts}
                                    label={`${intl.formatMessage({ id: "FORM.DISTRICT", defaultMessage: "District" })}`}
                                    name="district"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting || !values.district}
                                    component={XAutoComplete}
                                    options={counties}
                                    label={`${intl.formatMessage({ id: "FORM.COUNTY", defaultMessage: "County" })}`}
                                    name="county"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting || !values.county}
                                    component={XAutoComplete}
                                    options={subCounties}
                                    label={`${intl.formatMessage({ id: "FORM.SUBCOUNTY", defaultMessage: "Sub-county " })}`}
                                    name="subCounty"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting || !values.subCounty}
                                    component={XAutoComplete}
                                    options={parishes}
                                    label={`${intl.formatMessage({ id: "FORM.PARISH", defaultMessage: "Parish" })}`}
                                    name="parish"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting || !values.parish}
                                    component={XAutoComplete}
                                    options={villages}
                                    label={`${intl.formatMessage({ id: "FORM.VILLAGE", defaultMessage: "Village" })}`}
                                    name="village"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({ id: "FORM.LATITUDE", defaultMessage: "Latitude" })}`}
                                    name="latitude"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({ id: "FORM.LONGITUDE", defaultMessage: "Longitude" })}`}
                                    name="longitude"
                                />
                            </div>
                        </div>
                    </ModalWrapper>
                );
            }}
        </Formik>
    );
};

export default CreateCollectionCentre;