import React, { useEffect, useRef, useState } from "react";
import TableWrapper from "../../components/shared/TableWrapper";
import { IButtonProps } from "../../components/shared/PageHeader";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import { useAuth } from "../auth";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { CreateBatchForm } from "./modals/CreateBatchForm";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { IMenuItem } from "../../../utils/MainMenu";
import { InventoryInnerMenu } from "./InventoryInnerMenu";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Modules } from "../../interfaces/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { IIdNamePair } from "../settings/interfaces/IHub";
import { SellPurchasesForm } from "./modals/SellPurchasesForm";
import { DownloadCsvForm } from "./modals/DownloadCsvForm";
import { ImportPurchasesForm } from "./modals/ImportPurchasesForm";
import XPagination from "../../components/shared/XPagination";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import SelectProductForm from "./modals/SelectProductForm";
import toast from "react-hot-toast";
import { remoteRoutes } from "../../../data/constants";
import { INVENTORY_PURCHASES_CONSTANTS } from "../../../data/redux/inventory/purchasesReducer";
import {
  toastMessages,
  overrideToastDefaults,
} from "../../../data/toastDefaults";
import {
  IApiResponse,
  IPaginationMetadata,
} from "../../interfaces/IApiResponse";
import { buildUrl } from "../../../utils/queryBuilder";
import { get } from "../../../utils/ajax";
import PurchaseDetails from "./PurchaseDetails";
import { IPurchase } from "./interfaces/IPurchase";
import { FormattedMessage, useIntl } from "react-intl";
import debounce from "lodash.debounce";
import DeletePurchasesFrom from "./modals/DeletePurchasesFrom";
import { authService } from "../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
  IsMerchant,
} from "../../hooks/roleChecker";
import { usePageData } from "../../../_theme/layout/core";

const calculateUnitPrice = (totalPrice: number, quantity: number): string => {
  if (!quantity || quantity === 0) return "0";

  const unitPrice = totalPrice / quantity;

  const formattedUnitPrice = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(unitPrice);

  return unitPrice === 0 ? "0" : formattedUnitPrice;
};

export const PurchaseColumns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.DATEOFPURCHASE",
    label: "Date of Purchase",
    id: "date",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.FARMER",
    label: "Farmer",
    id: "farmerName",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.PRODUCT",
    label: "Product",
    id: "productName",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.VARIETY",
    label: "Variety",
    id: "varietyName",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.QUANTITYINKG",
    label: "Quantity (kg)",
    id: "quantity",
    link: undefined,
    isNumberFormat: true,
    isDate: false,
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.UNITPRICE",
    label: "Unit Price (UGX)",
    id: "unitPrice",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.COST",
    label: "Cost (UGX)",
    id: "totalPrice",
    link: undefined,
    isNumberFormat: true,
    isDate: false,
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.AGENT",
    label: "Agent",
    id: "agentName",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.COMPLIANCESTATUS",
    label: "Compliance Status",
    id: "compliance",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-center",
    hidden: false,
  },
];

interface Props {
  showHeader?: boolean;
}

export const useDynamicColumns = () => {
  const location = useLocation();

  return PurchaseColumns.map((column) => {
    if (
      column.id === "compliance" &&
      (location.pathname.includes("/farmers") ||
        location.pathname.includes("/batches") ||
        location.pathname.includes("/movements"))
    ) {
      return { ...column, hidden: true };
    }
    return column;
  });
};

export const Purchases = ({ showHeader = true }: Props) => {
  document.title = "Inventory > Purchases";
  const intl = useIntl();

  const PurchaseStatus = {
    All: "All" as const,
    Batched: true as const,
    Unbatched: false as const,
  };

  type PurchaseStatusType =
    (typeof PurchaseStatus)[keyof typeof PurchaseStatus];
  const [activeSection, setActiveSection] = useState<PurchaseStatusType>(
    PurchaseStatus.All,
  );

  const { currentUser } = useAuth();
  const location = useLocation();

  const [currentStatus, setCurrentStatus] = useState<boolean | null>(null);
  const [showCheckboxes, setShowCheckboxes] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const urlParams = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get("page") || 1;
  const { data, loading }: any = useSelector(
    (state: IState) => state.purchases,
  );

  const [selected, setSelected] = useState<any[]>([]);
  const [selectedPurchase, setSelectedPurchase] = useState<IPurchase | null>(
    null,
  );
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

  const [page, setPage] = useState<number>(Number(currentPage));
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [pagination, setPagination] = useState<IPaginationMetadata>({
    pageSize: 25,
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPage: false,
    totalItems: 0,
    totalPages: 0,
  });

  const [showActionButtons, setShowActionButtons] = useState<boolean>(false);
  const [batchedQuantity, setBatchedQuantity] = useState(0);

  const activePage = location.pathname.toLowerCase();
  const [subMenuItems, setSubMenuItems] = useState<IMenuItem[] | undefined>([]);

  const { setPageTitle } = usePageData()

  useEffect(() => {
    setPageTitle("Inventory / Purchases")
  }, [])

  const [selectedProduct, setSelectedProduct] = useState<IIdNamePair>({
    id: "",
    name: "",
  });
  const [productId, setProductId] = useState<string | null | undefined>(
    undefined,
  );
  const [varietyId, setVarietyId] = useState<string | null | undefined>(
    undefined,
  );
  const [selectedId, setSelectedId] = useState<string | null | undefined>(
    undefined,
  );
  const [selectedVariety, setSelectedVariety] = useState<
    { id: string; name: string } | undefined
  >(undefined);

  const actionButtons: IButtonProps[] = [
    {
      label: `${intl.formatMessage({
        id: "BUTTONS.CREATEABATCH",
        defaultMessage: "Create a Batch",
      })} (${Number(batchedQuantity).toLocaleString()} kg)`,
      cssClass: "btn btn-primary ms-2",
      dataTarget: "#create-batch",
      dataToggle: "modal",
    },
    // {
    //     label: `Sell batch`,
    //     cssClass: "btn btn-success ms-2",
    //     dataTarget: "#sell-purchases",
    //     dataToggle: "modal"
    // },
    // {
    //     label: `Download CSV`,
    //     cssClass: "btn btn-secondary ms-2",
    //     dataTarget: "#download-csv",
    //     dataToggle: "modal"
    // }
  ];

  useEffect(() => {
    const items = InventoryInnerMenu(intl, activePage);
    setSubMenuItems(items);
  }, [activePage]);

  const handleCheckRow = (row: any) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];
      const index = newSelected.indexOf(row.id);

      if (index > -1) {
        newSelected.splice(index, 1);
        setBatchedQuantity((prevQuantity) => {
          return prevQuantity - row.quantity;
        });
      } else {
        newSelected.push(row.id);
        setBatchedQuantity((prevQuantity) => {
          return prevQuantity + row.quantity;
        });

        setSelectedProduct({ id: row.productId, name: row.productName });
        setSelectedVariety({ id: row.varietyId, name: row.varietyName });
      }

      return newSelected;
    });
  };

  const handleRowClick = (row: any) => {
    setDrawerVisible(!drawerVisible);
    setSelectedPurchase(row);
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        setBatchedQuantity(0);
        setSelectedProduct({ id: "", name: "" });
        setSelectedVariety({ id: "", name: "" });
        return [];
      } else {
        const totalQuantity = rows.reduce(
          (accumulator: number, item) => accumulator + item.quantity,
          0,
        );
        setBatchedQuantity(totalQuantity);

        if (rows.length > 0) {
          setSelectedProduct({
            id: rows[0].productId,
            name: rows[0].productName,
          });
          setSelectedVariety({
            id: rows[0].varietyId,
            name: rows[0].varietyName,
          });
        }
        return rows.map((row) => row.id);
      }
    });
  };

  useEffect(() => {
    setShowActionButtons(selected && selected.length > 0);
  }, [selected]);

  const handleSelectId = (
    id: string | null | undefined,
    isVariety: boolean,
  ) => {
    setSelectedId(id);
    if (isVariety) {
      setVarietyId(id);
      setProductId(undefined);
    } else {
      setProductId(id);
      setVarietyId(undefined);
    }
  };

  useEffect(() => {
    fetchPurchases(page, pagination.pageSize, searchTerm);
  }, [
    varietyId,
    productId,
    page,
    searchTerm,
    searchTerm,
    location.pathname,
    urlParams.farmerId,
    currentUser?.hubId,
    selectedId,
    currentStatus,
  ]);

  const setLoading = (status: boolean) => {
    dispatch({
      type: INVENTORY_PURCHASES_CONSTANTS.LOADING_PURCHASES,
      payload: status,
    });
  };

  const handleSectionClick = (section: PurchaseStatusType) => {
    setActiveSection(section);
  };

  useEffect(() => {
    switch (activeSection) {
      case PurchaseStatus.Batched:
        setCurrentStatus(true);
        break;
      case PurchaseStatus.Unbatched:
        setCurrentStatus(false);
        break;
      default:
        setCurrentStatus(null);
    }
  }, [activeSection]);

  const fetchPurchases = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    dispatch({
      type: INVENTORY_PURCHASES_CONSTANTS.LOADING_PURCHASES,
      payload: true,
    });

    let url = "";

    let params: any = { page, pageSize, hubId: currentUser?.hubId };

    if (currentStatus === true) {
      params.isBatched = true;
    } else if (currentStatus === false) {
      params.isBatched = false;
    }

    const isFarmersPage = location.pathname.includes("farmers");

    if (isFarmersPage) {
      params.farmerId = urlParams.farmerId;
    }

    if (varietyId) {
      params.varietyId = selectedId;
    } else if (productId) {
      params.productId = selectedId;
    }

    if (searchTerm) {
      params.searchTerm = searchTerm;
    }

    if (location.pathname.includes("/batches")) {
      url = buildUrl(
        remoteRoutes.inventoryService,
        "/batches/purchases",
        params,
      );
    } else {
      url = buildUrl(remoteRoutes.inventoryService, "/purchases", params);
    }

    get(
      url,
      (response: IApiResponse) => {
        const { data, paginationMetadata } = response;
        setPagination(paginationMetadata);

        dispatch({
          type: INVENTORY_PURCHASES_CONSTANTS.FETCH_PURCHASES,
          payload: data,
        });

        toast.success(toastMessages.default.success, overrideToastDefaults);
      },
      (error) => {
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {
        dispatch({
          type: INVENTORY_PURCHASES_CONSTANTS.STOP_FETCH,
        });
        setLoading(false);
      },
    );
  };

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchTerm(value);
      setPage(1);
      fetchPurchases(page, pagination.pageSize, value);
    }, 800),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
    setPage(1);
  };

  const columns = useDynamicColumns();

  const formattedData = data.map(
    (row: {
      totalPrice: number;
      quantity: number;
      date: string;
      unitName: string;
      farmCompliance?: { status: string };
    }) => ({
      ...row,
      date: printNaturalDateShort(row.date),
      unitPrice: row.quantity
        ? calculateUnitPrice(row.totalPrice, row.quantity)
        : "N/A",
      compliance: row.farmCompliance?.status || "N/A",
    }),
  );

  const sectionStyle = (section: any) => ({
    backgroundColor: activeSection === section ? "#f1f1f1" : "transparent",
    color: activeSection === section ? "#1F5A2D" : "inherit",
    fontWeight: activeSection === section ? "bold" : "normal",
    cursor: "pointer",
    width: "auto",
  });

  const handleDeleteCompleted = () => {
    setSelectedIds([]);
    setShowActionButtons(false);
  };

  useEffect(() => { }, [selectedIds]);

  const [loader, setLoader] = React.useState(true);
  const [roles, setRoles] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoader(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);
  const isMerchant = IsMerchant(roles);

  return (
    <>
      {showHeader && (
        <>
          <div className={"py-3"}>
            <InnerMenu module={Modules.Inventory} />
          </div>

          <div
            className="bg-white rounded-3 shadow-lg mb-3 row w-100 m-0 p-0"
            style={{
              height: 35,
              border: "1px solid #d1d1d1",
            }}
          >
            <div
              className="d-flex bg-grey align-items-center justify-content-center h-100"
              style={{ marginLeft: 20, ...sectionStyle("All") }}
              onClick={() => {
                handleSectionClick(PurchaseStatus.All);
                setSelectedId(undefined);
              }}
            >
              <FormattedMessage id={"TABS.ALL"} defaultMessage={"All"} />
            </div>
            <div
              className="d-flex bg-grey align-items-center justify-content-center h-100"
              style={sectionStyle(PurchaseStatus.Unbatched)}
              onClick={() => {
                handleSectionClick(PurchaseStatus.Unbatched);
              }}
            >
              <FormattedMessage
                id={"TABS.UNBATCHED"}
                defaultMessage={"Unbatched"}
              />
            </div>
            <div
              className="d-flex bg-grey align-items-center justify-content-center h-100 px-2"
              style={sectionStyle(PurchaseStatus.Batched)}
              onClick={() => {
                handleSectionClick(PurchaseStatus.Batched);
                setSelectedId(undefined);
              }}
            >
              <FormattedMessage
                id={"TABS.BATCHED"}
                defaultMessage={"Batched"}
              />
            </div>
          </div>
        </>
      )}

      <TableWrapper>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="input-group w-25">
            <input
              type="text"
              // onKeyUp={(event) => handleSearch(event)}
              ref={searchInputRef}
              onChange={handleSearchChange}
              className="form-control"
              placeholder={intl.formatMessage({
                id: "TABLE.SEARCH",
                defaultMessage: "Type to search...",
              })}
            />
            <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>

          <div className="action-buttons">
            {showHeader && (
              <>
                {/*<button*/}
                {/*    type="button"*/}
                {/*    data-bs-toggle={"modal"}*/}
                {/*    data-bs-target={"#import-purchases"}*/}
                {/*    className="btn btn-sm btn-outline-dark ms-2"*/}
                {/*>*/}
                {/*    <FontAwesomeIcon icon={faFileImport} />*/}
                {/*    <span className="ms-2">*/}
                {/*        <FormattedMessage*/}
                {/*            id="BUTTONS.IMPORT"*/}
                {/*            defaultMessage="Import"*/}
                {/*        />*/}
                {/*      </span>*/}
                {/*</button>*/}

                {(isGlobalAdmin || isHubAdmin || isMerchant) && (
                  <>
                    {!showActionButtons &&
                      activeSection === PurchaseStatus.Unbatched &&
                      selectedId === undefined && (
                        <>
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm ms-2"
                            onClick={() => setShowCheckboxes(!showCheckboxes)}
                          >
                            <span className="ms-2">
                              <FormattedMessage
                                id={"BUTTONS.SELECTPURCHASES"}
                                defaultMessage={"Select Purchases"}
                              />
                            </span>
                          </button>
                        </>
                      )}
                  </>
                )}

                {(isGlobalAdmin ||
                  isHubAdmin ||
                  isHubManager ||
                  isMerchant) && (
                    <>
                      {!showActionButtons &&
                        activeSection === PurchaseStatus.Unbatched &&
                        !showCheckboxes && (
                          <>
                            <button
                              type="button"
                              data-bs-toggle={"modal"}
                              data-bs-target={"#select-product"}
                              className="btn btn-primary btn-sm ms-2"
                            >
                              <span className="ms-2">
                                <FormattedMessage
                                  id={"BUTTONS.CREATEABATCH"}
                                  defaultMessage={"Create a Batch"}
                                />
                              </span>
                            </button>
                          </>
                        )}
                    </>
                  )}

                {selectedIds.length > 0 &&
                  activeSection === PurchaseStatus.Unbatched &&
                  selectedId === undefined && (
                    <>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm ms-2"
                        data-bs-toggle={"modal"}
                        data-bs-target={"#delete-purchases"}
                      >
                        <span className="ms-2">
                          {selectedIds.length === 1 ? (
                            <FormattedMessage
                              id={"BUTTONS.DELETEPURCHASE"}
                              defaultMessage={"Delete Purchase"}
                            />
                          ) : (
                            <FormattedMessage
                              id={"BUTTONS.DELETEPURCHASES"}
                              defaultMessage={"Delete Purchases"}
                            />
                          )}
                        </span>
                      </button>
                    </>
                  )}
              </>
            )}

            {showHeader &&
              showActionButtons &&
              activeSection === PurchaseStatus.Unbatched &&
              selectedId !== undefined &&
              actionButtons.map((button, index) => {
                return (
                  <button
                    data-bs-toggle={button.dataToggle}
                    data-bs-target={button.dataTarget}
                    className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                    key={index}
                    onClick={button.onClick}
                  >
                    {button.processing
                      ? `${intl.formatMessage({
                        id: "LOADERS.PLEASEWAIT",
                        defaultMessage: "Please wait...",
                      })}`
                      : button.label}
                  </button>
                );
              })}
          </div>
        </div>

        <XTable
          selected={selected}
          checked={
            (selectedId !== undefined &&
              activeSection === PurchaseStatus.Unbatched) ||
            (showCheckboxes && activeSection === PurchaseStatus.Unbatched)
          }
          onSelectAll={(rows) => {
            const allIds = rows.map((row) => row.id);

            setSelectedIds((prev) =>
              prev.length === allIds.length ? [] : allIds,
            );

            handleSelectAll(rows);
          }}
          onRowClick={(row) => handleRowClick(row)}
          onCheckRow={(row) => {
            if (
              (selectedId !== undefined &&
                activeSection === PurchaseStatus.Unbatched) ||
              showCheckboxes
            ) {
              const isSelected = selectedIds.includes(row.id);

              setSelectedIds((prev) =>
                isSelected
                  ? prev.filter((id) => id !== row.id)
                  : [...prev, row.id],
              );
              handleCheckRow(row);
            }
          }}
          data={formattedData}
          columns={columns}
          loading={loading}
        />
        <XPagination
          dataLength={data.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>

      <DeletePurchasesFrom
        ids={selectedIds}
        pagination={pagination}
        onDeleteCompleted={handleDeleteCompleted}
      />
      <SelectProductForm onSelectId={handleSelectId} />
      <SellPurchasesForm purchases={selected} />
      <CreateBatchForm
        quantity={batchedQuantity}
        purchaseIds={selected}
        product={selectedProduct}
        variety={selectedVariety}
      />
      <DownloadCsvForm purchases={selected} />
      <PurchaseDetails
        close={() => setDrawerVisible(false)}
        show={drawerVisible}
        purchaseId={selectedPurchase?.id}
        pagination={pagination}
      />
      <ImportPurchasesForm />
    </>
  );
};
