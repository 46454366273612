import { useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";
import React, { useEffect, useState } from "react";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { get, put } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../../data/toastDefaults";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { XAutoComplete } from "../../../../components/shared/forms/XAutoComplete";
import { useAuth } from "../../../auth";
import { HUBS_CONSTANTS } from "../../../../../data/redux/settings/hubsReducer";
import { HubSchema } from "../interfaces/IHubDto";
import { IHub } from "../../../settings/interfaces/IHub";
import { IApiResponse } from "../../../../interfaces/IApiResponse";
import { InputAdornment } from "@mui/material";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useGeographicData } from "../../../../hooks/useGeographicData";

type Props = {
  initialValues: IHub
  hubId?: string
}

const EditClientForm = ({ initialValues, hubId }: Props) => {
  const dispatch = useDispatch();
  const urlParams = useParams();
  const { currentUser } = useAuth();
  const intl = useIntl();
  const [hubs, setHubs] = useState<IHub[]>([]);

  const hubOptions = hubs?.map((hub: IHub) => ({
    label: hub.name,
    id: hub.id
  }))

  const parentHub = hubOptions?.find(
    (h) => h.id == initialValues.parentHubId,
  ) ?? { id: "", label: "" };

  const [modalKey, setModalKey] = useState(Date.now());

  const {
    districts,
    counties,
    subCounties,
    parishes,
    villages,
    setSelectedDistrictId,
    setSelectedCountyId,
    setSelectedSubCountyId,
    setSelectedParishId
  } = useGeographicData();

  useEffect(() => {
    if (initialValues.districtId) {
      setSelectedDistrictId(initialValues.districtId);
    }
  }, [initialValues.districtId, setSelectedDistrictId]);

  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

    const url = buildUrl(remoteRoutes.onboardingService, `/hubs/${values.id}`);
    const data: IHub = {
      id: values.id,
      name: values.name,
      email: values.email,
      phoneNumber: values.phoneNumber,
      district: values.district.label,
      districtId: values.district.id,
      subCounty: values.subCounty.label,
      county: values.county.label,
      parish: values.parish.label,
      parentHubId: values.parentHubId.id ? values.parentHubId.id : null,
      village: values.village.label,
      postalAddress: values.postalAddress,
      minimumAggregationUnit: values.minimumAggregationUnit,
    }

    put(url, data, (response) => {

      CloseModal("edit-client", () => {
        setSubmitting(false);
        resetForm();
      })

      dispatch({
        type: HUBS_CONSTANTS.UPDATE_DATA,
        payload: data
      })

      toast.success(toastMessages.default.success, overrideToastDefaults)
      setModalKey(Date.now());
    }, (error) => {
      setSubmitting(false);
      toast.error(toastMessages.default.fail, overrideToastDefaults)
    }, () => {

    })
  }

  useEffect(() => {
    handleFetchHubs(1, 1000)
  }, []);

  const handleFetchHubs = (page: number, pageSize: number) => {
    if (remoteRoutes.onboardingService) {
      const hubId = urlParams?.hubId ?? currentUser?.hubId;
      let params: any = { page, pageSize };

      const url = buildUrl(remoteRoutes.onboardingService, "/hubs", params);

      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;
          setHubs(data);

          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: HUBS_CONSTANTS.STOP_FETCH });
        },
      );
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const value = event.target.value;
    if (value.length <= 9) {
      setFieldValue("phoneNumber", value);
    }
  };

  const validationSchema = HubSchema(intl)

  return (
    <Formik
      initialValues={{
        ...initialValues,
        parentHubId: parentHub,
        district: initialValues.district ? {
          label: initialValues.district,
          value: initialValues.district,
          id: initialValues.districtId
        } : null,
        county: initialValues.county ? {
          label: initialValues.county,
          value: initialValues.county,
          id: null
        } : null,
        subCounty: initialValues.subCounty ? {
          label: initialValues.subCounty,
          value: initialValues.subCounty,
          id: null
        } : null,
        parish: initialValues.parish ? {
          label: initialValues.parish,
          value: initialValues.parish,
          id: null
        } : null,
        village: initialValues.village ? {
          label: initialValues.village,
          value: initialValues.village,
          id: null
        } : null,
        phoneNumber: initialValues.phoneNumber && initialValues.phoneNumber?.length > 9 ? initialValues.phoneNumber?.substring(4) : initialValues.phoneNumber,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}>

      {({ handleSubmit, isSubmitting, values, setFieldValue }) => {

        const districtID = values.district?.id ?? null;
        setSelectedDistrictId(districtID)
        const countyID = values.county?.id ?? null;
        setSelectedCountyId(countyID)
        const subCountyID = values.subCounty?.id ?? null;
        setSelectedSubCountyId(subCountyID)
        const parishID = values.parish?.id ?? null;
        setSelectedParishId(parishID)

        return <ModalWrapper
          id="edit-client"
          title={`${intl.formatMessage({ id: "FORM.TITLE.EDITCLIENT", defaultMessage: "Edit Client" })}`}
          size="lg"
          key={modalKey}
          submitting={isSubmitting}
          handleSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled={isSubmitting}
                as={XTextField}
                label="Name"
                name="name"
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled={isSubmitting}
                as={XTextField}
                label={`${intl.formatMessage({ id: "FORM.MINIMUMAGGREGATIONUNIT", defaultMessage: "Minimum Aggregation Unit" })}`}
                name="minimumAggregationUnit"
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled={isSubmitting}
                component={XAutoComplete}
                options={hubOptions?.filter(
                  (hub) => hub.id !== initialValues.id,
                )}
                label={`${intl.formatMessage({ id: "FORM.PARENTHUB", defaultMessage: "Parent hub" })}`}
                name="parentHubId"
                value={values.parentHubId}
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled={isSubmitting}
                as={XTextField}
                label={`${intl.formatMessage({ id: "FORM.POSTALADDRESS", defaultMessage: "Postal Address" })}`}
                name="postalAddress"
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled={isSubmitting}
                as={XTextField}
                label={`${intl.formatMessage({ id: "FORM.PHONENUMBER", defaultMessage: "Phone Number" })}`}
                name="phoneNumber"
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+256</InputAdornment>
                  ),
                  maxLength: 9,
                  onKeyPress: handleKeyPress,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(event, setFieldValue),
                }}
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled={isSubmitting}
                as={XTextField}
                label={`${intl.formatMessage({ id: "FORM.EMAIL", defaultMessage: "Email" })}`}
                name="email"
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled={isSubmitting}
                component={XAutoComplete}
                options={districts}
                onChange={(event: any, value: string) => setFieldValue('district', value)}
                value={values.district}
                label={`${intl.formatMessage({ id: "FORM.DISTRICT", defaultMessage: "District" })}`}
                name="district"
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled={isSubmitting}
                component={XAutoComplete}
                options={counties}
                label={`${intl.formatMessage({ id: "FORM.COUNTY", defaultMessage: "County" })}`}
                name="county"
              />
            </div>
            <div className="col-lg-6">
              <Field
                type={"string"}
                disabled={isSubmitting}
                component={XAutoComplete}
                options={subCounties}
                label={`${intl.formatMessage({ id: "FORM.SUBCOUNTY", defaultMessage: "Sub-county" })}`}
                name="subCounty"
              />
            </div>

            <div className="col-lg-3">
              <Field
                type={"string"}
                disabled={isSubmitting}
                component={XAutoComplete}
                options={parishes}
                label={`${intl.formatMessage({ id: "FORM.PARISH", defaultMessage: "Parish" })}`}
                name="parish"
              />
            </div>

            <div className="col-lg-3">
              <Field
                type={"string"}
                disabled={isSubmitting}
                component={XAutoComplete}
                options={villages}
                label={`${intl.formatMessage({ id: "FORM.VILLAGE", defaultMessage: "Village" })}`}
                name="village"
              />
            </div>
          </div>
        </ModalWrapper>
      }}
    </Formik>
  );
};

export default EditClientForm;
