import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import { PageHeader } from "../../components/shared/PageHeader";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import React, { useEffect, useRef, useState } from "react";
import {
  IInputsDistributionState,
  INPUTS_DISTRIBUTION_CONSTANTS,
} from "../../../data/redux/inputs/inputsReducer";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../data/types";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";
import { IInputDistribution } from "./interfaces/IInputDistribution";
import { useNavigate, useParams } from "react-router-dom";
import {
  IApiResponse,
  IPaginationMetadata,
} from "../../interfaces/IApiResponse";
import { buildUrl } from "../../../utils/queryBuilder";
import { remoteRoutes } from "../../../data/constants";
import { get } from "../../../utils/ajax";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../data/toastDefaults";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEllipsisV,
  faSearch,
  faShapes,
} from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../components/shared/XPagination";
import debounce from "lodash.debounce";
import SellInputForm from "./modals/SellInputForm";
import MoveInputsForm from "./modals/MoveInputsForm";
import { Loading } from "../../components/shared/Loading";
import { authService } from "../../../data/oidc/AuthService";
import {
  IsGlobalAdmin,
  IsHubAdmin,
  IsHubManager,
} from "../../hooks/roleChecker";

interface IInputs {
  id: string;
  name: string;
  quantity: number;
  unit: string;
  collectionCentreName: string;
  collectionCentreId: string;
}

const InputDetails = () => {
  const { data }: IInputsDistributionState = useSelector(
    (state: IState) => state.inputs,
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const urlParams = useParams();
  const collectionCentreId = urlParams.collectionCentreId;
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const [filteredData, setFilteredData] = useState<IInputDistribution[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<IPaginationMetadata>({
    pageSize: 15,
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPage: false,
    totalItems: 0,
    totalPages: 0,
  });
  const [inputs, setInputs] = useState<IInputs[]>([]);
  const [collectionCentreName, setCollectionCentreName] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [selectedInput, setSelectedInput] = useState<IInputDistribution | null>(
    null,
  );

  const centre: IInputDistribution = data.find(
    (centre: IInputDistribution) => centre.id === collectionCentreId,
  ) as IInputDistribution;

  useEffect(() => {
    if (collectionCentreId) {
      handleFetchData(
        page,
        pagination.pageSize,
        collectionCentreId,
        searchTerm,
      );
    }

    setLoading(false);
  }, [collectionCentreId, searchTerm]);

  const handleFetchData = (
    page: number,
    pageSize: number,
    collectionCentreId?: string,
    searchTerm?: string,
  ) => {
    if (remoteRoutes.profiles) {
      let params: any = { collectionCentreId, searchTerm, page, pageSize };

      const url = buildUrl(
        remoteRoutes.inputsService,
        `/ledger/centre/`,
        params,
      );

      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          const filteredInputs = data.filter(
            (item: any) => item.collectionCentreId === collectionCentreId,
          );

          if (filteredInputs && filteredInputs.length > 0) {
            setCollectionCentreName(filteredInputs[0].collectionCentreName);
            setInputs(
              filteredInputs
                .map((item) => ({
                  id: item.inputId,
                  name: item.inputName,
                  quantity: item.quantity,
                  unit: item.unit,
                  collectionCentreName: item.collectionCentreName,
                  collectionCentreId: item.collectionCentreId,
                }))
                .sort((a, b) => a.name.localeCompare(b.name)),
            );
          }

          setFilteredData(filteredInputs);
          setPagination(paginationMetadata);

          dispatch({
            type: INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA,
            payload: data,
          });

          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: INPUTS_DISTRIBUTION_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchTerm(value);
      setPage(1);
      handleFetchData(page, pagination.pageSize, collectionCentreId, value);
    }, 800),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const [roles, setRoles] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await authService.getRoles();
      setRoles(roles);
      setLoading(false);
    };
    fetchRoles();
  }, []);

  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);
  const isHubManager = IsHubManager(roles);
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumbs([
      { label: "Inputs", url: undefined },
      { label: "Stock", url: "/inputs/stock" },
      { label: collectionCentreName || "Loading...", url: undefined },
    ]);

    document.title = `Stock > ${collectionCentreName}`;
  }, [collectionCentreName]);

  return (
    <>
      {loading && <PageHeaderLoader />}
      {!loading && (
        <>
        <div className="py-3">
          <InnerMenu module={Modules.Inputs} />
        </div>
      </>
      )}

      <div className="card border-0 px-3 pt-3">
        <div className="row">
          <div className="col-6 d-flex align-items-center">
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ cursor: "pointer" }}
              className="me-3 pb-2"
              onClick={() => navigate(-1)}
            />
            <h6>{collectionCentreName}</h6>
          </div>

          <div className="col-6">
            <div className="d-flex align-items-center justify-content-end">
              <div className="input-group w-50">
                <input
                  type="text"
                  ref={searchInputRef}
                  onChange={handleSearchChange}
                  className="form-control"
                  placeholder="Type to search..."
                />
                <span className="input-group-text" id="addon-wrapping">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div
            className="loader d-flex justify-content-center align-items-center"
            style={{ height: 150 }}
          >
            <Loading />
          </div>
        ) : (
          <div className="pt-3 row">
            {inputs.map((item, index) => (
              <div className="col-lg-3 mb-3" key={index}>
                <div className="input-card border-1 border w-100 p-2">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="badge-container">
                      {item.quantity === 0 && (
                        <span className="danger-badge">Out of stock</span>
                      )}
                      {item.quantity > 0 && (
                        <span className="success-badge">In Stock</span>
                      )}
                    </div>

                    {(isHubManager || isHubManager || isHubAdmin) && (
                      <div className="btn-group" role="group">
                        <button
                          className="card-action-buttons"
                          data-bs-toggle="dropdown"
                          disabled={item.quantity === 0 || item.quantity < 0}
                        >
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="btnGroupDrop1"
                        >
                          <li>
                            <a
                              onClick={() => setSelectedInput(item)}
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#move-inputs"
                              href="#"
                            >
                              Move Input
                            </a>
                          </li>
                          <li className="dropdown-divider"></li>
                          <li>
                            <a
                              onClick={() => setSelectedInput(item)}
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#sell-input"
                              href="#"
                            >
                              Sell Input
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <div className="input-icon">
                      <FontAwesomeIcon icon={faShapes} />
                    </div>

                    <div className="d-flex align-items-center flex-column pt-1">
                      <span className="fs-4 fw-bold">{item.name}</span>
                      <span className="text-small">
                        {item.quantity.toLocaleString()} - {item.unit}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <XPagination
          currentPage={page}
          pagination={pagination}
          dataLength={filteredData.length}
          setPage={setPage}
        />
      </div>
      <SellInputForm selectedInput={selectedInput} />
      <MoveInputsForm selectedInput={selectedInput} />
    </>
  );
};

export { InputDetails };
