import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { toastMessages, overrideToastDefaults } from "../../../../data/toastDefaults";
import { Field, FieldArray, Formik } from "formik";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { IOption, XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { IProcessRun } from "../interfaces/IProcessRun";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get, post } from "../../../../utils/ajax";
import { useDispatch } from "react-redux";
import moment from "moment";
import { PROCESS_RUN_CONSTANTS } from "../../../../data/redux/processing/processRunsReducer";
import { ProcessingSchema } from "../schemas/ProcessingSchema";
import { useIntl } from "react-intl";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    processRun: IProcessRun
}

const EndProcessForm = ({ processRun }: Props) => {

    const intl = useIntl();

    const [modalKey, setModalKey] = useState(Date.now());
    const [varieties, setVarieties] = useState<IOption[]>([]);
    const newOutputRef = useRef<HTMLInputElement | null>(null);

    const dispatch = useDispatch();

    const initialValues = {
        id: processRun.id,
        productId: processRun.productId,
        outputs: [
            {
                varietyId: { id: "", label: "" },
                variety: "",
                quantity: "",
                unit: { id: "", label: "" },
            }
        ],
    }

    useEffect(() => {
        handelFetchVarieties();
    }, [processRun, dispatch]);

    const handelFetchVarieties = () => {
        const url = buildUrl(remoteRoutes.onboardingService, `/products/${initialValues.productId}`);

        get(url, (response) => {

            const data = response;

            if (!data || !data.varieties) {
                console.error("No varieties found in product data");
                setVarieties([]);
                return;
            }

            const varietyList = data.varieties
                .filter((variety: { id: string }) =>
                    processRun
                        ? variety.id !== processRun.inputVarietyId
                        : true
                )
                .map((variety: { id: any; name: any }) => ({
                    id: variety.id ?? '',
                    label: variety.name ?? 'Unnamed Variety',
                }))
                .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));

            setVarieties(varietyList);

        }, (error) => {
            console.error("Error fetching data:", error);
        });
    };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.processingService, `/process-runs/end`)
        const transformedOutputs = values.outputs.map((output: any) => ({
            varietyId: output.varietyId?.id,
            variety: output.varietyId?.label,
            quantity: output.quantity,
            unit: output.unit?.label,
        }));

        const data = {
            processRunId: values.id,
            endTime: moment().toISOString().replace('Z', '+00:00'),
            outputs: transformedOutputs,
            comment: values.comment,
        }

        post(url, data, (response) => {

            CloseModal("end-process", () => {
                setSubmitting(false);
                resetForm();
            });


            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());

            dispatch({
                type: PROCESS_RUN_CONSTANTS.UPDATE_PROCESS_RUN,
                payload: {
                    ...processRun,
                    endTime: moment().toISOString().replace('Z', '+00:00'),
                    outputs: transformedOutputs,
                    comment: values.comment,
                    status: 2
                }
            })

        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {
        })
    }

    const units = [
        { id: "kg", label: "kg" },
        { id: "mt", label: "mt" }
    ]

    const validationSchema = ProcessingSchema(intl)

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
                return (
                    <ModalWrapper
                        id="end-process"
                        title="End Process"
                        size="md"
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}
                        submitBtnLabel="End Process"
                    >
                        <div className="scrollable-content">
                            <div className="row">
                                <FieldArray name="outputs">
                                    {({ push, remove }) => (
                                        <>
                                            {values.outputs.map((output, index) => (
                                                <div key={index} className="col-lg-12">
                                                    <div className="row mb-3">
                                                        <div className="col-lg-12">
                                                            <Field
                                                                type="string"
                                                                disabled={isSubmitting}
                                                                component={XAutoComplete}
                                                                options={varieties.filter(variety =>
                                                                    !values.outputs.slice(0, index).some(
                                                                        prevOutput => prevOutput.varietyId?.id === variety.id
                                                                    )
                                                                )}
                                                                label={`Output Variety ${index + 1}`}
                                                                name={`outputs[${index}].varietyId`}
                                                                inputRef={index === values.outputs.length - 1 ? newOutputRef : null}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Field
                                                                type="number"
                                                                disabled={isSubmitting}
                                                                as={XTextField}
                                                                label="Output Quantity"
                                                                name={`outputs[${index}].quantity`}
                                                            />
                                                        </div>
                                                        <div className="col-lg-5">
                                                            <Field
                                                                type="string"
                                                                disabled={isSubmitting}
                                                                component={XAutoComplete}
                                                                label="Output Unit"
                                                                name={`outputs[${index}].unit`}
                                                                options={units}
                                                            />
                                                        </div>
                                                        {index > 0 && (
                                                            <div
                                                                className="col-lg-1 align-content-center"
                                                                style={{ cursor: "pointer", color: "grey", marginLeft: 0 }}
                                                                onClick={() => remove(index)}
                                                            >
                                                                <FontAwesomeIcon icon={faCircleMinus} color="#FF0000" size="lg" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            {values.outputs[values.outputs.length - 1]?.varietyId && (
                                                <div
                                                    style={{ cursor: "pointer", color: "grey" }}
                                                    onClick={() =>
                                                        push({ variety: "", varietyId: "", quantity: "", unit: "" })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlus} color="#DF901F" size="lg" />
                                                    <span className="mx-2 align-items-center">Add another output</span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </FieldArray>
                                <div className="col-lg-12">
                                    <Field
                                        type="string"
                                        disabled={isSubmitting}
                                        as={XTextField}
                                        label="Comments"
                                        name="comment"
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalWrapper>
                );
            }}

        </Formik>
    )
}

export default EndProcessForm