import React, { useEffect } from "react";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import { usePageData } from "../../../_theme/layout/core";

const Dashboard = () => {

    const { setPageTitle } = usePageData()

    useEffect(() => {
        setPageTitle("Processing / Dashboard")
    }, [])

    return (
        <>
            <div className="py-3">
                <InnerMenu module={Modules.Processing} />
            </div>
        </>
    )
}

export default Dashboard