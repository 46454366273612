import { useDispatch } from "react-redux";
import { useAuth } from "../../../auth";
import { useState } from "react";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { IContactPerson } from "../../interfaces/IHub";
import { post } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { Field, Formik } from "formik";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { XAutoComplete } from "../../../../components/shared/forms/XAutoComplete";
import { IPaginationMetadata } from "../../../../interfaces/IApiResponse";
import { useIntl } from "react-intl";
import { ContactPersonSchema } from "../interfaces/ContactPersonSchema";
import { useGeographicData } from "../../../../hooks/useGeographicData";

type Props = {
    hubId?: string;
    pagination: IPaginationMetadata
};

interface FormValues {
    name: string;
    district: { id: string; label: string } | null;
    county: { id: string; label: string } | null;
    hubId: string | undefined;
    subCounty: { id: string; label: string } | null;
    parish: { id: string; label: string } | null;
    village: { id: string; label: string } | null;
    phoneNumber: string | null;
    email: string | null;
}

const AddContactPersonForm = ({ hubId, pagination }: Props) => {
    const intl = useIntl();

    const dispatch = useDispatch();
    const { currentUser } = useAuth();

    const [modalKey, setModalKey] = useState(Date.now());

    const {
        districts,
        counties,
        subCounties,
        parishes,
        villages,
        setSelectedDistrictId,
        setSelectedCountyId,
        setSelectedSubCountyId,
        setSelectedParishId
    } = useGeographicData();

    const initialValues: FormValues = {
        name: "",
        district: null,
        hubId: hubId ?? currentUser?.hubId,
        subCounty: null,
        county: null,
        parish: null,
        village: null,
        email: null,
        phoneNumber: null
    };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const data: IContactPerson = {
            ...values,
            district: values.district?.label ?? '',
            districtId: values.district?.id ?? '',
            subCounty: values.subCounty?.label ?? '',
            county: values.county?.label ?? '',
            parish: values.parish?.label ?? '',
            village: values.village?.label ?? '',
        };

        const url = buildUrl(remoteRoutes.onboardingService, '/contact/persons');
        post(url, data, (response) => {

            CloseModal("new-contact-person", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_CONTACT_PEOPLE,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            });

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });
    };

    const validationSchema = ContactPersonSchema(intl)

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            {({ handleSubmit, isSubmitting, values }) => {
                const districtID = values.district?.id ?? null;
                setSelectedDistrictId(districtID)
                const countyID = values.county?.id ?? null;
                setSelectedCountyId(countyID)
                const subCountyID = values.subCounty?.id ?? null;
                setSelectedSubCountyId(subCountyID)
                const parishID = values.parish?.id ?? null;
                setSelectedParishId(parishID)
                return (
                    <ModalWrapper
                        id={"new-contact-person"}
                        title={`${intl.formatMessage({
                            id: "FORM.TITLE.ADDCONTACTPERSON",
                            defaultMessage: "Add a Contact Person"
                        })}`}
                        size="md"
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}>

                        <div className="row">
                            <div className="col-lg-6">
                                <Field
                                    type="string"
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Name"
                                    name="name"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type="string"
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({
                                        id: "FORM.PHONENUMBER",
                                        defaultMessage: "Phone Number"
                                    })}`}
                                    name="phoneNumber"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type="string"
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({ id: "FORM.EMAIL", defaultMessage: "Email" })}`}
                                    name="email"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type="string"
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={districts}
                                    label={`${intl.formatMessage({ id: "FORM.DISTRICT", defaultMessage: "District" })}`}
                                    name="district"
                                    value={values.district}
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting || !values.district}
                                    component={XAutoComplete}
                                    options={counties}
                                    label={`${intl.formatMessage({ id: "FORM.COUNTY", defaultMessage: "County" })}`}
                                    name="county"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting || !values.county}
                                    component={XAutoComplete}
                                    options={subCounties}
                                    label={`${intl.formatMessage({ id: "FORM.SUBCOUNTY", defaultMessage: "Sub-county " })}`}
                                    name="subCounty"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting || !values.subCounty}
                                    component={XAutoComplete}
                                    options={parishes}
                                    label={`${intl.formatMessage({ id: "FORM.PARISH", defaultMessage: "Parish" })}`}
                                    name="parish"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type="string"
                                    disabled={isSubmitting || !values.parish}
                                    component={XAutoComplete}
                                    options={villages}
                                    label={`${intl.formatMessage({ id: "FORM.VILLAGE", defaultMessage: "Village" })}`}
                                    name="village"
                                />
                            </div>
                        </div>
                    </ModalWrapper>
                )
            }}
        </Formik>
    );
};

export default AddContactPersonForm;
