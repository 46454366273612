import { IPaginationMetadata } from "../../../app/interfaces/IApiResponse";
import { IProcessRun } from "../../../app/modules/processing/interfaces/IProcessRun";

export const PROCESS_RUN_CONSTANTS = {
    DELETE_PROCESS_RUN: "DELETE_PROCESS_RUN",
    END_PROCESS_RUN: "END_PROCESS_RUN",
    FETCH_PROCESS_RUNS: "FETCH_PROCESS_RUNS",
    FILTER_PROCESS_RUN_DATA: "FILTER_PROCESS_RUN_DATA",
    GET_PROCESS_RUN: "GET_PROCESS_RUN",
    LOADING_PROCESS_RUNS: "LOADING_PROCESS_RUNS",
    PAGINATION: "PAGINATION",
    POST_PROCESS_RUN: "POST_PROCESS_RUN",
    RESET_FILTER: "RESET_FILTER",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    STOP_FETCH: "STOP_FETCH",
    UPDATE_PROCESS_RUN: "UPDATE_PROCESS_RUN",
}

export interface IProcessRunState {
    loading: boolean
    data: IProcessRun[]
    pagination: IPaginationMetadata
    filter: {
        show: boolean,
        data: IProcessRun[],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: IProcessRunState = {
    loading: false,
    data: [],
    pagination: {
        currentPage: 1,
        pageSize: 25,
        hasPreviousPage: false,
        hasNextPage: false,
        totalItems: 0,
        totalPages: 0
    },
    filter: {
        key: '',
        show: false,
        data: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function processRunsReducer(state = initialState, action: any) {
    switch (action.type) {
        case PROCESS_RUN_CONSTANTS.FILTER_PROCESS_RUN_DATA:
            return { ...state, ...action.payload, show: !state.filter.show }

        case PROCESS_RUN_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return { ...initialState, reset: true }

        case PROCESS_RUN_CONSTANTS.SET_SORT_PARAMS:
            return { ...state, ...action.payload }

        case PROCESS_RUN_CONSTANTS.PAGINATION:
            return { ...state, ...action.payload }

        case PROCESS_RUN_CONSTANTS.FETCH_PROCESS_RUNS: {
            return { ...state, loading: false, data: action.payload, reset: false }
        }

        case PROCESS_RUN_CONSTANTS.POST_PROCESS_RUN: {
            return { ...state, data: [action.payload, ...state.data] }
        }

        case PROCESS_RUN_CONSTANTS.UPDATE_PROCESS_RUN: {
            return {
                ...state,
                data: state.data.map(processRun =>
                    processRun.id === action.payload.id ? action.payload : processRun
                )
            }
        }
        
        case PROCESS_RUN_CONSTANTS.END_PROCESS_RUN: {
            return {
                ...state,
                data: state.data.map(processRun =>
                    processRun.id === action.payload.id ? { ...processRun, ...action.payload } : processRun
                )
            };
        }

        case PROCESS_RUN_CONSTANTS.END_PROCESS_RUN: {
            return {
                ...state,
                data: state.data.map(processRun =>
                    processRun.id === action.payload.id ? { ...processRun, ...action.payload } : processRun
                )
            };
        }

        case PROCESS_RUN_CONSTANTS.DELETE_PROCESS_RUN: {
            return {
                data: state.data.filter(processRun => processRun.id !== action.payload.id),
            }
        }

        case PROCESS_RUN_CONSTANTS.LOADING_PROCESS_RUNS: {
            return { ...state, loading: action.payload }
        }

        case PROCESS_RUN_CONSTANTS.GET_PROCESS_RUN: {
            return { ...state, requestDetails: action.payload }
        }

        case PROCESS_RUN_CONSTANTS.STOP_FETCH: {
            return { ...state, loading: false }
        }

        default: {
            return state
        }
    }
}