import * as Yup from "yup";
import { IntlShape } from "react-intl";

export const ProcessingSchema = (intl: IntlShape) => Yup.object({
    outputs: Yup.array().of(
        Yup.object().shape({
            varietyId: Yup.object()
                .shape({
                    id: Yup.string().required("Required"),
                    label: Yup.string().required("Required"),
                })
                .required("Required"),
            quantity: Yup.number()
                .required("Required")
                .positive("Quantity must be positive"),
            unit: Yup.object()
                .shape({
                    id: Yup.string().required("Required"),
                    label: Yup.string().required("Required"),
                })
                .required("Required"),
        })
    ),
})
