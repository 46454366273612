import React, { useEffect, useState } from "react";
import { IProcessRun } from "../interfaces/IProcessRun";
import toast from "react-hot-toast";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { toastMessages, overrideToastDefaults } from "../../../../data/toastDefaults";
import { Field, FieldArray, Formik } from "formik";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { IOption, XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { useDispatch } from "react-redux";
import { get, put } from "../../../../utils/ajax";
import { PROCESS_RUN_CONSTANTS } from "../../../../data/redux/processing/processRunsReducer";
import { ProcessingSchema } from "../schemas/ProcessingSchema";
import { useIntl } from "react-intl";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../auth";

interface Props {
    initialValues: IProcessRun
}

const EditProcessRunForm = ({ initialValues }: Props) => {

    const intl = useIntl();

    const [modalKey, setModalKey] = useState(Date.now());
    const [varieties, setVarieties] = useState<IOption[]>([]);
    const dispatch = useDispatch();
    const { currentUser } = useAuth();

    useEffect(() => {
        handelFetchVarieties();
    }, [initialValues, dispatch]);

    const handelFetchVarieties = () => {
        const url = buildUrl(remoteRoutes.onboardingService, `/products/${initialValues.productId}`);

        get(url, (response) => {

            const data = response;

            if (!data || !data.varieties) {
                console.error("No varieties found in product data");
                setVarieties([]);
                return;
            }

            const varietyList = data.varieties
                .filter((variety: { id: string }) =>
                    initialValues
                        ? variety.id !== initialValues.inputVarietyId
                        : true
                )
                .map((variety: { id: any; name: any }) => ({
                    id: variety.id ?? '',
                    label: variety.name ?? 'Unnamed Variety',
                }))
                .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));

            setVarieties(varietyList);

        }, (error) => {
            console.error("Error fetching data:", error);
        });
    };


    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.processingService, `/process-runs/${initialValues.id}`)
        const transformedOutputs = values.outputs.map((output: any) => ({
            varietyId: output.varietyId?.id,
            variety: output.varietyId?.label,
            quantity: output.quantity,
            unit: output.unit?.label,
            hubId: currentUser?.hubId
        }));

        const data: IProcessRun = {
            ...values,
            outputs: transformedOutputs,
            comment: values.comment,
        }

        put(url, data, (response) => {

            CloseModal("edit-process-run", () => {
                setSubmitting(false);
                resetForm();
            });

            dispatch({
                type: PROCESS_RUN_CONSTANTS.UPDATE_PROCESS_RUN,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {
        })
    }

    const units = [
        { id: "kg", label: "kg" },
        { id: "mt", label: "mt" }
    ]

    const validationSchema = ProcessingSchema(intl)

    return (
        <Formik
            initialValues={{
                ...initialValues,
                outputs: initialValues.outputs ? initialValues.outputs.map(output => ({
                    varietyId: output.varietyId ? {
                        label: output.variety,
                        value: output.varietyId,
                        id: output.varietyId
                    } : null,
                    unit: output.unit ? {
                        label: output.unit,
                        value: output.unit,
                        id: output.unit
                    } : null,
                    quantity: output.quantity ?? null
                })) : [{
                    varietyId: null,
                    unit: null,
                    quantity: null
                }]
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, isSubmitting, values }) => {
                return <ModalWrapper
                    id={'edit-process-run'}
                    title={'Edit Process Run'}
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="scrollable-content">
                        <div className="row">
                            <FieldArray name="outputs">
                                {({ push, remove }) => {
                                    return (
                                        <>
                                            {values.outputs.length === 0 && (
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <Field
                                                                type="string"
                                                                disabled={isSubmitting}
                                                                component={XAutoComplete}
                                                                options={varieties}
                                                                label={`Output Variety 1`}
                                                                name={`outputs[0].varietyId`}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Field
                                                                type="number"
                                                                disabled={isSubmitting}
                                                                as={XTextField}
                                                                label="Output Quantity"
                                                                name={`outputs[0].quantity`}
                                                            />
                                                        </div>
                                                        <div className="col-lg-5">
                                                            <Field
                                                                type="string"
                                                                disabled={isSubmitting}
                                                                component={XAutoComplete}
                                                                label="Output Unit"
                                                                name={`outputs[0].unit`}
                                                                options={units}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {values.outputs.map((output, index) => (
                                                <div key={index} className="col-lg-12">
                                                    <div className="row mb-3">
                                                        <div className="col-lg-12">
                                                            <Field
                                                                type="string"
                                                                disabled={isSubmitting}
                                                                component={XAutoComplete}
                                                                options={varieties.filter(variety =>
                                                                    !values.outputs.slice(0, index).some(
                                                                        prevOutput => prevOutput.varietyId?.id === variety.id
                                                                    )
                                                                )}
                                                                label={`Output Variety ${index + 1}`}
                                                                name={`outputs[${index}].varietyId`}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Field
                                                                type="number"
                                                                disabled={isSubmitting}
                                                                as={XTextField}
                                                                label="Output Quantity"
                                                                name={`outputs[${index}].quantity`}
                                                            />
                                                        </div>
                                                        <div className="col-lg-5">
                                                            <Field
                                                                type="string"
                                                                disabled={isSubmitting}
                                                                component={XAutoComplete}
                                                                label="Output Unit"
                                                                name={`outputs[${index}].unit`}
                                                                options={units}
                                                            />
                                                        </div>
                                                        <div
                                                            className="col-lg-1 align-content-center"
                                                            style={{ cursor: "pointer", color: "grey", marginLeft: 0 }}
                                                            onClick={() => remove(index)}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleMinus} color="#FF0000" size="lg" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {values.outputs.length > 0 && (
                                                <div
                                                    style={{ cursor: "pointer", color: "grey" }}
                                                    onClick={() =>
                                                        push({ variety: "", varietyId: "", quantity: "", unit: "" })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlus} color="#DF901F" size="lg" />
                                                    <span className="mx-2 align-items-center">Add another output</span>
                                                </div>
                                            )}
                                        </>
                                    )
                                }}
                            </FieldArray>
                            <div className="col-lg-12">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Comments"
                                    name="comment"
                                />
                            </div>
                        </div>
                    </div>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default EditProcessRunForm