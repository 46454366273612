import React, { useEffect, useState } from "react";
import SideMenu from "../../../_theme/layout/components/SideMenu";
import RolesPage from "./permission/RolesPage";
import { HubCostCentersPage } from "./costCenters/HubCostCentersPage";
import { IHub, IProduct } from "./interfaces/IHub";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../auth";
import { authService } from "../../../data/oidc/AuthService";
import { HubProcessesPage } from "./processes/HubProcessesPage";
import { HubCollectionCentersPage } from "./collectionCenters/HubCollectionCentersPage";
import { HubContactPeoplePage } from "./contactPeople/HubContactPeoplePage";
import { Users } from "../onboarding/userManagement/Users";
import HubProductsPage from "./products/HubProductsPage";
import HubInputsPage from "./inputs/HubInputsPage";
import SystemProducts from "./systemProducts/SystemProducts";
import Units from "./units/Units";
import { IsGlobalAdmin, IsHubAdmin } from "../../hooks/roleChecker";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useIntl } from "react-intl";
import HubAgentsPage from "./agents/HubAgentsPage";
import { usePageData } from "../../../_theme/layout/core";

export const Settings = () => {
  const intl = useIntl();

  const roles = authService.getRoles();
  const isGlobalAdmin = IsGlobalAdmin(roles);

  const defaultLanding = isGlobalAdmin
    ? "System Products"
    : "Collection Centers";

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const auth = useAuth();
  const currentUser = auth.currentUser;
  const dispatch = useDispatch();

  const hub: IHub | any = authService.getHub();

  const urlParams = useParams();
  const hubId = urlParams?.hubId ?? currentUser?.hubId;

  const {
    products,
    inputs,
    costCentres,
    contactPersons,
    collectionCentres,
    processes,
  } = hub;

  const formatLabelForDisplay = (label: string) => {
    const labelMap: { [key: string]: string } = {
      SystemProducts: "System Products",
      CollectionCenters: "Collection Centers",
      CostCenters: "Cost Centers",
      Roles: "Roles",
      HubProducts: "Hub Products",
      ContactPeople: "Contact People",
      HubInputs: "Inputs",
      Units: "Units",
      Processes: "Processes",
      Agents: "Agents",
    };
    return labelMap[label] || label;
  };

  const currentTab = formatLabelForDisplay(
    searchParams.get("tab") || defaultLanding,
  );

  const [activeItem, setActiveItem] = useState<string>(currentTab);

  const formatLabelForUrl = (label: string) => label.replace(/\s+/g, "");

  const { setPageTitle } = usePageData()

  useEffect(() => {
    const formattedLabel = formatLabelForUrl(currentTab);
    navigate(`/settings?tab=${formattedLabel}`, { replace: true });
    document.title = `Settings > ${formattedLabel}`;

    setPageTitle(`Settings / ${formattedLabel}`)

  }, [currentTab, navigate]);

  const renderContent = () => {
    switch (activeItem) {
      case "Roles":
        return (
          <RolesPage
            title={intl.formatMessage({
              id: "HEADERS.SETTINGS.ROLES",
              defaultMessage: "Settings > Roles",
            })}
          />
        );
      case "Hub Products":
        return <HubProductsPage products={products} />;
      case "Inputs":
        return (
          <HubInputsPage
            title={intl.formatMessage({
              id: "HEADERS.SETTINGS.INPUTS",
              defaultMessage: "Settings > Inputs",
            })}
            hubId={hubId}
          />
        );
      case "Cost Centers":
        return (
          <HubCostCentersPage title={"Settings > Cost Centers"} hubId={hubId} />
        );
      case "Processes":
        return (
          <HubProcessesPage
            title={intl.formatMessage({
              id: "HEADERS.SETTINGS.PROCESSES",
              defaultMessage: "Settings > Processes",
            })}
            hubId={hubId}
          />
        );
      case "Collection Centers":
        return (
          <HubCollectionCentersPage
            title={`${intl.formatMessage({
              id: "HEADERS.SETTINGS.COLLECTIONCENTRES",
              defaultMessage: "Settings > Collection Centres",
            })}`}
            hubId={hubId}
          />
        );
      case "Agents":
        return (
          <HubAgentsPage
            title={`${intl.formatMessage({
              id: "HEADERS.SETTINGS.AGENTS",
              defaultMessage: "Settings > Agents",
            })}`}
            hubId={hubId}
          />
        );
      case "Contact People":
        return <HubContactPeoplePage />;
      case "System Products":
        return <SystemProducts />;
      case "Units":
        return <Units />;
      default:
        return <div>Select a menu item</div>;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-start mt-3">
        <div className="me-4">
          <SideMenu activeItem={activeItem} setActiveItem={setActiveItem} />
        </div>
        <div className="card-body">{renderContent()}</div>
      </div>
    </>
  );
};
