import { format, isValid, parseISO } from "date-fns";
import moment from "moment";

export const dateFormat = "dd.MM.yyyy";
export const dateTimeFormat = "dd.MM.yyyy HH:mm";
export const standardDateTimeFormat = "dd-MM-yyyy HH:mm";
export const standardDateTimeFormatFileName = "dd.MM.yyyy HH.mm";
export const standardDateTimeFormatSeconds = "dd-MM-yyyy HH:mm:ss";
export const standardDateFormat = "dd-MM-yyyy";
export const monthYearFormat = "MMMM, yyyy";
export const yearDateTime = "yyyy-MM-dd'T'HH:mm:ss";
export const yearMonthDayDate = "yyyy-MM-dd";
export const NIRADate = "yyyy-MM-dd";
export const standardTime = "HH:mm:ss";
export const naturalDateshort = "dd MMM yyyy";
export const naturalDateTimeshort = "dd MMM yyyy 'at' hh:mm a";

export const printDateTime = (value: any): string => {
    if (typeof value === "string") {
        return printDateTime(strToDate(value));
    }
    if (isValid(value)) return format(value, dateTimeFormat);
    else return "";
};

export const printBirthday = (value: any): string => {
    if (typeof value === "string") {
        return printBirthday(strToDate(value));
    }
    if (isValid(value)) return format(value, "dd MMM");
    else return "";
};

export const printMonth = (value: any): string => {
    if (typeof value === "string") {
        return printMonth(strToDate(value));
    }
    if (isValid(value)) return format(value, "MMM");
    else return "";
};

export const printDay = (value: any): string => {
    if (typeof value === "string") {
        return printDay(strToDate(value));
    }
    if (isValid(value)) return format(value, "dd");
    else return "";
};

export const printShortDate = (value: any): string => {
    if (typeof value === "string") {
        return printShortDate(strToDate(value));
    }
    if (isValid(value)) return format(value, "dd MMM");
    else return "";
};

export const printDayOfMonth = (value: any): string => {
    if (typeof value === "string") {
        return printDayOfMonth(strToDate(value));
    }
    if (isValid(value)) return format(value, "dd");
    else return "";
};

export const printDate = (value: any): string => {
    if (typeof value === "string") {
        return printDate(strToDate(value));
    }
    if (isValid(value)) return format(value, dateFormat);
    else return "";
};

export const printStdDate = (value: any): string => {
    if (typeof value === "string") {
        return printDate(strToDate(value));
    }
    if (isValid(value)) return format(value, standardDateFormat);
    else return "";
};

export const printStdDatetime = (value: any): string => {
    if (typeof value === "string") {
        return printDate(strToDate(value));
    }
    if (isValid(value)) return format(value, standardDateTimeFormat);
    else return "";
};

export const printStdDatetimeSeconds = (value: any): string => {
    if (typeof value === "string") {
        return printDate(strToDate(value));
    }
    if (isValid(value)) return format(value, standardDateTimeFormatSeconds);
    else return "";
};

export const strToDate = (str: string): Date | null => {
    try {
        return parseISO(str);
    } catch (e) {
        return null;
    }
};
export const printMonthYear = (value: any): string => {
    if (typeof value === "string") {
        return printMonthYear(strToDate(value));
    }
    if (isValid(value)) return format(value, monthYearFormat);
    else return "";
};
export const printYearDateTime = (value: any): string => {
    // if (typeof value === "string") {
    //   return printMonthYear(strToDate(value));
    // }

    const dateValue = moment(value, ['YYYY-MM-DDTHH:mm:ss', 'DD.MM.YYYY', 'YYYY.MM.DD', 'YYYY-MM-DD'], false).toDate()

    if (isValid(dateValue)) return format(dateValue, yearDateTime);
    else return "";
};

export const printYearMonthDayDate = (value: any): string => {

    if (typeof value === "string") {
        return printMonthYear(strToDate(value));
    }
    if (isValid(value)) return format(value, yearMonthDayDate);
    else return "";
};

export const printDateLong = (value: any, isUtc: boolean = false): string => {
    if (value && typeof value === "string") {
        let x: any = strToDate(value)

        if (isUtc) {
            return moment(x, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss Z', 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss Z']).format("DD.MM.YYYY HH:mm")
        }

        return format(x, dateTimeFormat);
    }

    if (isValid(value)) return format(value, dateTimeFormat);
    else return "";
};

export const printDateShort = (value: any): string => {
    if (value && typeof value === "string") {

        let x: any = moment(value, ['DD.MM.YYYY', 'YYYY-MM-DDTHH:mm:ss']).format("DD.MM.YYYY")

        if (x.toLowerCase().includes('invalid')) {
            x = value
        }
        return x
    }

    if (isValid(value)) return format(value, dateFormat);
    else return "";
};

export const printDateToTimeStamp = (value: any): string => {
    if (value && typeof value === "string") {

        let x: any = moment(value, ['DD.MM.YYYY', 'YYYY-MM-DDTHH:mm:ss']).format("YYYY-MM-DDTHH:mm:ss")

        if (x.toLowerCase().includes('invalid')) {
            x = value
        }
        return x
    }

    if (isValid(value)) return format(value, dateFormat);
    else return "";
};

export const printDateToNIRA = (value: any): string => {
    if (value && typeof value === "string") {
        const x: any = strToDate(value)
        return format(x, NIRADate);
    }

    if (isValid(value)) return format(value, NIRADate);
    else return "";
};

export const printStdDatetimeFileName = (value: any): string => {
    if (value && typeof value === "string") {
        const x: any = strToDate(value)
        return format(x, standardDateTimeFormatFileName);
    }

    if (isValid(value)) return format(value, standardDateTimeFormatFileName);
    else return "";
};

export const printTime = (value: any): string => {
    if (value && typeof value === "string") {
        const x: any = strToDate(value)
        return format(x, standardTime);
    }

    if (isValid(value)) return format(value, standardTime);
    else return "";
};

export const printNaturalDateShort = (value: any): string => {
    if (value && typeof value === "string") {
        const x: any = strToDate(value)
        return format(x, naturalDateshort);
    }

    if (isValid(value)) return format(value, naturalDateshort);
    else return "";
};

export const printNaturalDateTimeShort = (value: any): string => {
    if (value && typeof value === "string") {
        const x: any = strToDate(value)
        return format(x, naturalDateTimeshort);
    }

    if (isValid(value)) return format(value, naturalDateTimeshort);
    else return "";
};

export const printAge = (value: string | number | undefined | null) => {
    if (typeof value === undefined || value === null) return "";
    const yearOfBirth = Number(value)
    const currentYear = moment().year()
    return (currentYear - yearOfBirth) + ' years old'
}

export const TimeAgo = (value: any): string => {
    if (value) {
        const inputDate = moment.utc(value)

        inputDate.utcOffset("+03:00")
        const currentDate = moment().utcOffset('+03:00')

        // Calculate the differences
        const yearsPassed = currentDate.diff(inputDate, 'years');
        const monthsPassed = currentDate.diff(inputDate, 'months');
        const weeksPassed = currentDate.diff(inputDate, 'weeks');
        const daysPassed = currentDate.diff(inputDate, 'days');
        const hoursPassed = currentDate.diff(inputDate, 'hours');
        const minutesPassed = currentDate.diff(inputDate, 'minutes');
        const secondsPassed = currentDate.diff(inputDate, 'seconds');

        // Find the most significant time unit
        if (yearsPassed > 0) {
            return `${yearsPassed} year${yearsPassed > 1 ? 's' : ''} ago`;
        } else if (monthsPassed > 0) {
            return `${monthsPassed} month${monthsPassed > 1 ? 's' : ''} ago`;
        } else if (weeksPassed > 0) {
            return `${weeksPassed} week${weeksPassed > 1 ? 's' : ''} ago`;
        } else if (daysPassed > 0) {
            return `${daysPassed} day${daysPassed > 1 ? 's' : ''} ago`;
        } else if (hoursPassed > 0) {
            return `${hoursPassed} hour${hoursPassed > 1 ? 's' : ''} ago`;
        } else if (minutesPassed > 0) {
            return `${minutesPassed} minute${minutesPassed > 1 ? 's' : ''} ago`;
        } else if (secondsPassed >= 0 && secondsPassed < 15) {
            return `just now`;
        } else {
            return `${secondsPassed} seconds ago`;
        }

    }

    return "";
};